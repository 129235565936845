import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Card, Button, CardContent, CardHeader, Unstable_Grid2 as Grid, TextField, Avatar, Typography, Divider, CardActions, FormControlLabel, Stack, Checkbox, CircularProgress, Alert } from '@mui/material';
import { ACCEPT_FILE_TYPE, ERROR_STR, FILE_MAX_SIZE, HELPER_TEXT, REFREEE_LEVELS, REFREEE_LEVEL_STATE, REG_FORM_KEY, REG_FORM_LABEL, RULE_PATTERN_VALUE, TERMS_AND_CONDITION_LINK, dialogButtonsProps, dialogProps, infoProps, snackbarProps } from '../../constant';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Info from '../../components/Info';
import Backdrop from '../../components/Backdrop';
import Dialog from '../../components/Dialog';
import { API_STFI } from '../../api/stfi';
import Snackbar from '../../components/Snackbar';
import ReactGA from "react-ga4";

export const RegistrationForm2 = () => {

	const [searchParams] = useSearchParams();
	const emailid = searchParams.get("emailId");
	console.log(emailid);
	const aadhaarCardNumber = searchParams.get("aadhaarCardNumber");
	const urlParam = searchParams.get("url");
	const action = searchParams.get("action");

	const navigate = useNavigate();

	const [backdrop, setBackdrop] = useState(false);

	const [rejectedReason, setRejectedReason] = useState("");

	const [loading, setLoading] = useState(false);

	const [dialog, setDialog] = useState(dialogProps.init());

	const [info, setInfo] = useState(infoProps.init());

	const [snackbar, setSnackbar] = useState(snackbarProps.init());
	snackbar.hide = () => {
		setSnackbar(snackbarProps.hide());
	};

	const [values, setValues] = useState({});
	const [errors, setErrors] = useState({});
	const [passportDetailsFilled, setPassportDetailsFilled] = useState(false);

	const [playerDetails, setPlayerDetails] = useState(false);
	const [officialDetails, setOfficialDetails] = useState(false);
	const [refereeDetails, setRefereeDetails] = useState(false);

	const [isApproved, setIsApproved] = useState(false);

	const [photo, setPhoto] = useState();

	const photoElement = useRef();
	const roleElement = useRef();

	const fetchData = useCallback(async () => {
		try {

			setLoading(true);
			// Authenticate the google token

			var reqParams = {};
			reqParams[REG_FORM_KEY.EMAIL] = emailid;
			reqParams[REG_FORM_KEY.AADHAAR_CARD_NUMBER] = aadhaarCardNumber;

			const response = await API_STFI.REGISTRATION_FORM_1(reqParams);

			if (response.status === 204) {

				var obj = {};
				obj[REG_FORM_KEY.AADHAAR_CARD_NUMBER] = aadhaarCardNumber;
				obj[REG_FORM_KEY.EMAIL] = emailid;
				obj[REG_FORM_KEY.PHONE_NUMBER] = "";
				obj[REG_FORM_KEY.FATHER_NAME] = "";
				obj[REG_FORM_KEY.MOTHER_NAME] = "";
				obj[REG_FORM_KEY.NATIONAL_PLAYED] = 0;
				obj[REG_FORM_KEY.INTERNATIONAL_PLAYED] = 0;
				obj[REG_FORM_KEY.NATIONAL_PARTICIPATED_AS_COACH] = 0;
				obj[REG_FORM_KEY.NATIONAL_PARTICIPATED_AS_MANAGER] = 0;
				obj[REG_FORM_KEY.INTERNATIONAL_PARTICIPATED_AS_COACH] = 0;
				obj[REG_FORM_KEY.INTERNATIONAL_PARTICIPATED_AS_MANAGER] = 0;
				obj[REG_FORM_KEY.NATIONAL_REFEREED] = 0;
				obj[REG_FORM_KEY.INTERNATIONAL_REFEREED] = 0;
				obj[REG_FORM_KEY.REFREEE_LEVEL] = REFREEE_LEVEL_STATE;
				obj[REG_FORM_KEY.PASSPORT_NUMBER] = "";
				obj[REG_FORM_KEY.PASSPORT_EXPIRY_MONTH_AND_YEAR] = "";
				obj[REG_FORM_KEY.PASSPORT_ISSUE_PLACE] = "";
				obj[REG_FORM_KEY.SHARE_CODE] = "";
				obj[REG_FORM_KEY.IS_PLAYER] = true;
				obj[REG_FORM_KEY.IS_COACH_OR_MANAGER] = true;
				obj[REG_FORM_KEY.IS_REFEREE] = true;
				obj[REG_FORM_KEY.REFREEE_PROOF] = "";
				obj[REG_FORM_KEY.PASSPORT_COPY] = "";
				obj[REG_FORM_KEY.PASSPORT_SIZE_PHOTO] = "";
				obj[REG_FORM_KEY.GOVERNMENT_PROOF] = "";
				obj[REG_FORM_KEY.AADHAAR_XML] = "";

				setValues(obj);

				// Hide loader
				setLoading(false);

				setInfo(infoProps.close());
			}
			else if (response.status === 200) {

				if (response.data.approved) {

					setIsApproved(true);

					var obj = {};
					obj[REG_FORM_KEY.AADHAAR_CARD_NUMBER] = aadhaarCardNumber;
					obj[REG_FORM_KEY.EMAIL] = emailid;
					obj[REG_FORM_KEY.NATIONAL_PLAYED] = 0;
					obj[REG_FORM_KEY.INTERNATIONAL_PLAYED] = 0;
					obj[REG_FORM_KEY.NATIONAL_PARTICIPATED_AS_COACH] = 0;
					obj[REG_FORM_KEY.NATIONAL_PARTICIPATED_AS_MANAGER] = 0;
					obj[REG_FORM_KEY.INTERNATIONAL_PARTICIPATED_AS_COACH] = 0;
					obj[REG_FORM_KEY.INTERNATIONAL_PARTICIPATED_AS_MANAGER] = 0;
					obj[REG_FORM_KEY.NATIONAL_REFEREED] = 0;
					obj[REG_FORM_KEY.INTERNATIONAL_REFEREED] = 0;
					obj[REG_FORM_KEY.REFREEE_LEVEL] = REFREEE_LEVEL_STATE;
					obj[REG_FORM_KEY.PASSPORT_NUMBER] = "";
					obj[REG_FORM_KEY.PASSPORT_EXPIRY_MONTH_AND_YEAR] = "";
					obj[REG_FORM_KEY.PASSPORT_ISSUE_PLACE] = "";
					if (!response.data.playerRegistered) obj[REG_FORM_KEY.IS_PLAYER] = true;
					if (!response.data.officialRegistered) obj[REG_FORM_KEY.IS_COACH_OR_MANAGER] = true;
					if (!response.data.refereeRegistered) obj[REG_FORM_KEY.IS_REFEREE] = true;
					obj[REG_FORM_KEY.REFREEE_PROOF] = "";
					obj[REG_FORM_KEY.PASSPORT_COPY] = "";

					setValues(obj);

					// Hide loader
					setLoading(false);

					setInfo(infoProps.close());
				}
				else {
					var buttons = [];
					buttons.push(dialogButtonsProps.init("Close", () => {
						setDialog(dialogProps.close());
						navigate(-1);
					}));

					setDialog(dialogProps.info("Error", "Registration is pending for approval. Please wait and try again after some time", buttons));
					setLoading(false);
				}

				// setInfo(infoProps.error("Already registered", true));
				// setLoading(false);
			}
			else {
				setInfo(infoProps.error(ERROR_STR.STD));
				setLoading(false);
			}

		} catch (error) {

			setValues({});
			// Hide loader
			setLoading(false);
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.error('Server responded with an error:', error.response.status, error.response);
				if (error.response.data && error.response.data.message && error.response.status !== 401) {
					setInfo(infoProps.error(error.response.data.message, true));
				}
				else setInfo(infoProps.error(error.response.statusText));
			} else if (error.request) {
				// The request was made but no response was received
				console.error('No response received from the server');
				setInfo(infoProps.error(ERROR_STR.NO_RESPONSE));
			} else {
				// Something happened in setting up the request that triggered an Error
				console.error('Error setting up the request:', error.message);
				setInfo(infoProps.error(ERROR_STR.STD));
			}
		}
	}, [aadhaarCardNumber, emailid]);

	const resubmit = useCallback(async () => {
		try {

			let url = new URL(atob(urlParam));
			// Authenticate the google token
			const response = await API_STFI.REGISTRATION_PREVIEW(url.pathname + url.search);

			if (response.status === 200 && response.data.rejectedFields) {

				if (response.data.rejectedFields.rejectedReason) setRejectedReason(response.data.rejectedFields.rejectedReason);

				let obj = {};
				obj[REG_FORM_KEY.AADHAAR_CARD_NUMBER] = response.data[REG_FORM_KEY.AADHAAR_CARD_NUMBER];
				obj[REG_FORM_KEY.EMAIL] = response.data[REG_FORM_KEY.EMAIL];

				if (response.data.rejectedFields[REG_FORM_KEY.PASSPORT_SIZE_PHOTO]) obj[REG_FORM_KEY.PASSPORT_SIZE_PHOTO] = "";
				if (response.data.rejectedFields[REG_FORM_KEY.GOVERNMENT_PROOF]) obj[REG_FORM_KEY.GOVERNMENT_PROOF] = "";

				if (response.data.rejectedFields[REG_FORM_KEY.PASSPORT_COPY]) {
					setPassportDetailsFilled(true);
					obj[REG_FORM_KEY.PASSPORT_NUMBER] = "";
					obj[REG_FORM_KEY.PASSPORT_EXPIRY_MONTH_AND_YEAR] = "";
					obj[REG_FORM_KEY.PASSPORT_ISSUE_PLACE] = "";
					obj[REG_FORM_KEY.PASSPORT_COPY] = "";
				}

				if (response.data.rejectedFields.player) {
					obj[REG_FORM_KEY.NATIONAL_PLAYED] = 0;
					obj[REG_FORM_KEY.INTERNATIONAL_PLAYED] = 0;
					obj[REG_FORM_KEY.IS_PLAYER] = true;
					setPlayerDetails(true);
				}
				if (response.data.rejectedFields.official) {
					obj[REG_FORM_KEY.NATIONAL_PARTICIPATED_AS_COACH] = 0;
					obj[REG_FORM_KEY.NATIONAL_PARTICIPATED_AS_MANAGER] = 0;
					obj[REG_FORM_KEY.INTERNATIONAL_PARTICIPATED_AS_COACH] = 0;
					obj[REG_FORM_KEY.INTERNATIONAL_PARTICIPATED_AS_MANAGER] = 0;
					obj[REG_FORM_KEY.IS_COACH_OR_MANAGER] = true;
					setOfficialDetails(true);
				}
				if (response.data.rejectedFields.referee) {
					obj[REG_FORM_KEY.NATIONAL_REFEREED] = 0;
					obj[REG_FORM_KEY.INTERNATIONAL_REFEREED] = 0;
					obj[REG_FORM_KEY.REFREEE_LEVEL] = REFREEE_LEVEL_STATE;
					obj[REG_FORM_KEY.REFREEE_PROOF] = "";
					obj[REG_FORM_KEY.IS_REFEREE] = true;
					setRefereeDetails(true);
				}

				/*
				if(response.data.status === "R") {

					let reason = response.data[REG_FORM_KEY.REJECTED_REASON];
					setRejectedReason(reason);
					if(reason && reason.indexOf("Document:") !== -1) {

						reason = reason.slice(("Document: ").length);
						let rejDoc = [];
						rejDoc = reason.split(",");
						rejDoc.forEach((key) => {
							if(key === REG_FORM_KEY.REFREEE_PROOF) setRefereeDetails(true);
							obj[key] = "";
						});
					}
					else {

						obj[REG_FORM_KEY.PHONE_NUMBER] = "";
						obj[REG_FORM_KEY.FATHER_NAME] = "";
						obj[REG_FORM_KEY.MOTHER_NAME] = "";
						obj[REG_FORM_KEY.NATIONAL_PLAYED] = 0;
						obj[REG_FORM_KEY.INTERNATIONAL_PLAYED] = 0;
						obj[REG_FORM_KEY.NATIONAL_PARTICIPATED_AS_COACH] = 0;
						obj[REG_FORM_KEY.NATIONAL_PARTICIPATED_AS_MANAGER] = 0;
						obj[REG_FORM_KEY.INTERNATIONAL_PARTICIPATED_AS_COACH] = 0;
						obj[REG_FORM_KEY.INTERNATIONAL_PARTICIPATED_AS_MANAGER] = 0;
						obj[REG_FORM_KEY.NATIONAL_REFEREED] = 0;
						obj[REG_FORM_KEY.INTERNATIONAL_REFEREED] = 0;
						obj[REG_FORM_KEY.REFREEE_LEVEL] = REFREEE_LEVEL_STATE;
						obj[REG_FORM_KEY.PASSPORT_NUMBER] = "";
						obj[REG_FORM_KEY.PASSPORT_EXPIRY_MONTH_AND_YEAR] = "";
						obj[REG_FORM_KEY.PASSPORT_ISSUE_PLACE] = "";
						obj[REG_FORM_KEY.SHARE_CODE] = "";
						obj[REG_FORM_KEY.IS_PLAYER] = true;
						obj[REG_FORM_KEY.IS_COACH_OR_MANAGER] = true;
						obj[REG_FORM_KEY.IS_REFEREE] = true;
						obj[REG_FORM_KEY.REFREEE_PROOF] = "";
						obj[REG_FORM_KEY.PASSPORT_COPY] = "";
						obj[REG_FORM_KEY.PASSPORT_SIZE_PHOTO] = "";
						obj[REG_FORM_KEY.GOVERNMENT_PROOF] = "";
						obj[REG_FORM_KEY.AADHAAR_XML] = "";
					}
				}
				else {

					if(response.data.playerRejectedDetails) {

					}

					if(response.data.officialRejectedDetails) {

					}

					if(response.data.RejectedDetails) {

					}


					setRejectedReason(response.data[REG_FORM_KEY.REJECTED_REASON]);
					let obj = {};
					let reason = response.data[REG_FORM_KEY.REJECTED_REASON];
					if(reason && reason.indexOf("Document:") !== -1) {

						reason = reason.slice(("Document: ").length);
						let rejDoc = [];
						rejDoc = reason.split(",");
						obj[REG_FORM_KEY.AADHAAR_CARD_NUMBER] = response.data[REG_FORM_KEY.AADHAAR_CARD_NUMBER];
						obj[REG_FORM_KEY.EMAIL] = response.data[REG_FORM_KEY.EMAIL];
						rejDoc.forEach((key) => {

							if(key === REG_FORM_KEY.REFREEE_PROOF) setRefereeDetails(true);
							obj[key] = "";
						});
					}
					else {
							obj[REG_FORM_KEY.AADHAAR_CARD_NUMBER] = response.data[REG_FORM_KEY.AADHAAR_CARD_NUMBER];
							obj[REG_FORM_KEY.EMAIL] = response.data[REG_FORM_KEY.EMAIL];
							obj[REG_FORM_KEY.PHONE_NUMBER] = "";
							obj[REG_FORM_KEY.FATHER_NAME] = "";
							obj[REG_FORM_KEY.MOTHER_NAME] = "";
							obj[REG_FORM_KEY.NATIONAL_PLAYED] = 0;
							obj[REG_FORM_KEY.INTERNATIONAL_PLAYED] = 0;
							obj[REG_FORM_KEY.NATIONAL_PARTICIPATED_AS_COACH] = 0;
							obj[REG_FORM_KEY.NATIONAL_PARTICIPATED_AS_MANAGER] = 0;
							obj[REG_FORM_KEY.INTERNATIONAL_PARTICIPATED_AS_COACH] = 0;
							obj[REG_FORM_KEY.INTERNATIONAL_PARTICIPATED_AS_MANAGER] = 0;
							obj[REG_FORM_KEY.NATIONAL_REFEREED] = 0;
							obj[REG_FORM_KEY.INTERNATIONAL_REFEREED] = 0;
							obj[REG_FORM_KEY.REFREEE_LEVEL] = REFREEE_LEVEL_STATE;
							obj[REG_FORM_KEY.PASSPORT_NUMBER] = "";
							obj[REG_FORM_KEY.PASSPORT_EXPIRY_MONTH_AND_YEAR] = "";
							obj[REG_FORM_KEY.PASSPORT_ISSUE_PLACE] = "";
							obj[REG_FORM_KEY.SHARE_CODE] = "";
							obj[REG_FORM_KEY.IS_PLAYER] = true;
							obj[REG_FORM_KEY.IS_COACH_OR_MANAGER] = true;
							obj[REG_FORM_KEY.IS_REFEREE] = true;
							obj[REG_FORM_KEY.REFREEE_PROOF] = "";
							obj[REG_FORM_KEY.PASSPORT_COPY] = "";
							obj[REG_FORM_KEY.PASSPORT_SIZE_PHOTO] = "";
							obj[REG_FORM_KEY.GOVERNMENT_PROOF] = "";
							obj[REG_FORM_KEY.AADHAAR_XML] = "";
						}

				}
				*/

				setValues(obj);

				// Hide loader
				setLoading(false);

				setInfo(infoProps.close());
			}
			else {
				setInfo(infoProps.error(ERROR_STR.STD));
				setLoading(false);
			}
		} catch (error) {

			setValues({});
			console.log(error);

			// Hide loader
			setLoading(false);
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.error('Server responded with an error:', error.response.status, error.response);
				if (error.response.data && error.response.data.message && error.response.status !== 401) {
					setInfo(infoProps.error(error.response.data.message, true));
				}
				else setInfo(infoProps.error(error.response.statusText));
			} else if (error.request) {
				// The request was made but no response was received
				console.error('No response received from the server');
				setInfo(infoProps.error(ERROR_STR.NO_RESPONSE));
			} else {
				// Something happened in setting up the request that triggered an Error
				console.error('Error setting up the request:', error.message);
				setInfo(infoProps.error(ERROR_STR.STD));
			}
		}
	}, [urlParam]);

	const createRegistration = useCallback(async (formData) => {
		try {

			setBackdrop(true);
			// Authenticate the google token

			const response = await API_STFI.CREATE_REGISTRATION(formData);

			if (response.status === 200) {
				var buttons = [];
				buttons.push(dialogButtonsProps.init("OK", () => {
					setDialog(dialogProps.close());
					navigate(-1);
				}));

				ReactGA.event({
					category: 'User',
					action: 'registration',
					label: "Create"
				});

				setDialog(dialogProps.info("Registration successfully submitted", "Request is pending for approval", buttons));
				setBackdrop(false);
			}
			else {
				setSnackbar(snackbarProps.error(ERROR_STR.STD));
				setBackdrop(false);
			}

		} catch (error) {

			// Hide loader
			setBackdrop(false);
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.error('Server responded with an error:', error.response.status, error.response);

				if (error.response.data && error.response.data.message) {
					setSnackbar(snackbarProps.error(error.response.data.message));
					setBackdrop(false);
				}
				else setSnackbar(snackbarProps.error(error.response.statusText));
			} else if (error.request) {
				// The request was made but no response was received
				console.error('No response received from the server');
				setSnackbar(snackbarProps.error(ERROR_STR.NO_RESPONSE));
			} else {
				// Something happened in setting up the request that triggered an Error
				console.error('Error setting up the request:', error.message);
				setSnackbar(snackbarProps.error(ERROR_STR.STD));
			}
		}
	}, []);

	const updateRegistration = useCallback(async (formData) => {
		try {

			setBackdrop(true);
			// Authenticate the google token

			const response = await API_STFI.REGISTRATION_UPDATE(formData);

			if (response.status === 200) {
				var buttons = [];
				buttons.push(dialogButtonsProps.init("OK", () => {
					setDialog(dialogProps.close());
					navigate(-2);
				}));

				setDialog(dialogProps.info("Registration successfully submitted", "Request is pending for approval", buttons));
				setBackdrop(false);
			}
			else {
				setSnackbar(snackbarProps.error(ERROR_STR.STD));
				setBackdrop(false);
			}

		} catch (error) {

			// Hide loader
			setBackdrop(false);
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.error('Server responded with an error:', error.response.status, error.response);
				setSnackbar(snackbarProps.error(error.response.statusText));
			} else if (error.request) {
				// The request was made but no response was received
				console.error('No response received from the server');
				setSnackbar(snackbarProps.error(ERROR_STR.NO_RESPONSE));
			} else {
				// Something happened in setting up the request that triggered an Error
				console.error('Error setting up the request:', error.message);
				setSnackbar(snackbarProps.error(ERROR_STR.STD));
			}
		}
	}, []);

	const addRoles = useCallback(async (formData) => {
		try {

			setBackdrop(true);
			// Authenticate the google token

			const response = await API_STFI.REGISTRATION_ADD_ROLES(formData);

			if (response.status === 200) {
				var buttons = [];
				buttons.push(dialogButtonsProps.init("OK", () => {
					setDialog(dialogProps.close());
					navigate(-2);
				}));

				setDialog(dialogProps.info("Registration successfully submitted", "Request is pending for approval", buttons));
				setBackdrop(false);
			}
			else {
				setSnackbar(snackbarProps.error(ERROR_STR.STD));
				setBackdrop(false);
			}

		} catch (error) {

			// Hide loader
			setBackdrop(false);
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.error('Server responded with an error:', error.response.status, error.response);
				setSnackbar(snackbarProps.error(error.response.statusText));
			} else if (error.request) {
				// The request was made but no response was received
				console.error('No response received from the server');
				setSnackbar(snackbarProps.error(ERROR_STR.NO_RESPONSE));
			} else {
				// Something happened in setting up the request that triggered an Error
				console.error('Error setting up the request:', error.message);
				setSnackbar(snackbarProps.error(ERROR_STR.STD));
			}
		}
	}, []);

	useEffect(() => {
		if (action === "resubmit") resubmit();
		else fetchData();

	}, [fetchData, action]);

	const onChange = useCallback(
		(event) => {

			const { name, value, checked } = event.target;

			setErrors({});

			if (action !== "resubmit") {

				if (name === REG_FORM_KEY.PASSPORT_NUMBER || name === REG_FORM_KEY.PASSPORT_EXPIRY_MONTH_AND_YEAR || name === REG_FORM_KEY.PASSPORT_ISSUE_PLACE || name === REG_FORM_KEY.PASSPORT_COPY) {
					if (value) setPassportDetailsFilled(true);
					else setPassportDetailsFilled(false);
				}
				else if (name === REG_FORM_KEY.IS_PLAYER) {
					if (checked) setPlayerDetails(true);
					else setPlayerDetails(false);
				}
				else if (name === REG_FORM_KEY.IS_COACH_OR_MANAGER) {
					if (checked) setOfficialDetails(true);
					else setOfficialDetails(false);
				}
				else if (name === REG_FORM_KEY.IS_REFEREE) {
					if (checked) setRefereeDetails(true);
					else setRefereeDetails(false);
				}
			}

			setValues((prevState) => ({
				...prevState,
				[name]: value
			}));
		},
		[setPassportDetailsFilled]
	);

	const handleSubmit = useCallback(
		async (event) => {

			event.preventDefault();

			const formData = new FormData();
			const formElements = event.target.elements;

			for (let i = 0; i < formElements.length; i++) {
				const element = formElements[i];
				if (element.name && element.type === 'checkbox') {
					formData.append(element.name, element.checked);
				}
				else if (element.type === 'file' && element.files.length) {
					if (element.files[0].size >= FILE_MAX_SIZE) {
						setSnackbar(snackbarProps.error(`'${element.files[0].name}' file size is more than 1 MB`));
						return;
					}
					formData.append(element.name, element.files[0]);
				}
				else if (element.name) {
					if (element.value) formData.append(element.name, element.value);
				}
			}

			if ((values[REG_FORM_KEY.PASSPORT_SIZE_PHOTO] !== undefined) && !formData.get(REG_FORM_KEY.PASSPORT_SIZE_PHOTO)) {
				errors[REG_FORM_KEY.PASSPORT_SIZE_PHOTO] = true;
				setSnackbar(snackbarProps.error('Upload photo'));
				photoElement.current.focus();
			}
			else {
				delete errors[REG_FORM_KEY.PASSPORT_SIZE_PHOTO];
			}

			if ((values[REG_FORM_KEY.IS_PLAYER] !== undefined || values[REG_FORM_KEY.IS_COACH_OR_MANAGER] !== undefined || values[REG_FORM_KEY.IS_REFEREE] !== undefined) && !formData.get(REG_FORM_KEY.IS_PLAYER) && !formData.get(REG_FORM_KEY.IS_COACH_OR_MANAGER) && !formData.get(REG_FORM_KEY.IS_REFEREE)) {
				errors[REG_FORM_KEY.IS_PLAYER] = true;
				setSnackbar(snackbarProps.error('Provide role'));
				roleElement?.current?.focus();
			}
			else delete errors[REG_FORM_KEY.IS_PLAYER];

			if (passportDetailsFilled) formData.append('updatePassport', true);

			setErrors({ ...errors });

			if (Object.keys(errors).length) {
				return;
			}

			for (let pair of formData.entries()) {
				if (!pair[1]) formData.delete(pair[0]);
			}

			// formData.delete("passportExpiryYearAndMonth");
			// formData.delete("passportProof");

			if (isApproved) {

				addRoles(formData);
			}
			else {
				if (action === "resubmit") {
					let url = new URL(atob(urlParam));
					const email = new URLSearchParams(url.search).get('email');
					formData.set("emailId", encodeURIComponent(email));
					updateRegistration(formData);
				}
				else createRegistration(formData);
			}

		},
		[errors, setErrors, setSnackbar, createRegistration]
	);

	const photoOnChange = useCallback((evt) => {

		const { files } = evt.target;

		if (FileReader && files && files.length) {
			var fr = new FileReader();
			fr.onload = function () {

				setPhoto(fr.result);
			}
			fr.readAsDataURL(files[0]);
		}
		else {
			console.log("Unable to load photo");
		}
	}, [setPhoto]);


	return (
		<>
			{
				(loading) ?
					<Box sx={{ width: '100%', height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
						<CircularProgress />
					</Box>
					:
					(Object.keys(values).length) ?
						<form
							onSubmit={handleSubmit}
						>
							{
								(rejectedReason) ?
									<Stack sx={{ width: '100%' }} mb={2}>
										<Alert severity="error">Rejected Reason - {rejectedReason}</Alert>
									</Stack>
									:
									<></>
							}
							<Grid
								container
								spacing={3}
							>
								{
									(values[REG_FORM_KEY.PASSPORT_SIZE_PHOTO] !== undefined) ?
										<Grid
											xs={12}
											md={4}
										>
											<Card sx={{ height: "100%" }} >
												<CardContent>
													<Box
														sx={{
															alignItems: 'center',
															display: 'flex',
															flexDirection: 'column',
														}}
													>
														<Avatar
															src={photo}
															sx={{
																height: 120,
																width: 120
															}}

														/>
													</Box>
												</CardContent>
												<Typography sx={{ padding: "0 10px", fontSize: 13, pb: 2, color: "red", textAlign: "center" }} >
													{
														(errors[REG_FORM_KEY.PASSPORT_SIZE_PHOTO] === true) ?
															HELPER_TEXT.IMAGE
															:
															''
													}
												</Typography>
												<Divider />
												<CardActions>
													<Button
														fullWidth
														variant="text"
														component="label"
														ref={photoElement}
													>
														Upload Photo *
														<input
															type="file"
															onChange={photoOnChange}
															hidden
															name={REG_FORM_KEY.PASSPORT_SIZE_PHOTO}
															accept={ACCEPT_FILE_TYPE.IMAGE}
														/>
													</Button>
												</CardActions>
											</Card>
										</Grid>
										:
										<></>
								}
								<Grid
									xs={12}
									md={4}
								>
									<Card sx={{ height: "100%" }}>
										<CardHeader
											subheader={""}
											title={"Basic Details"}
										/>
										<CardContent sx={{ pt: 0 }}>
											<Box sx={{ m: -1.5 }}>
												<Grid
													container
													spacing={3}
												>
													{
														(values[REG_FORM_KEY.EMAIL] !== undefined) ?
															<Grid
																xs={12}
															>
																<TextField
																	fullWidth
																	label={REG_FORM_LABEL.EMAIL}
																	name={REG_FORM_KEY.EMAIL}
																	required
																	disabled={true}
																	onChange={onChange}
																	value={values[REG_FORM_KEY.EMAIL]}
																	error={errors[REG_FORM_KEY.EMAIL]}
																	helperText={errors[REG_FORM_KEY.EMAIL] ? HELPER_TEXT.GMAIL : ''}
																	inputProps={{ pattern: RULE_PATTERN_VALUE.GMAIL }}
																/>
															</Grid>
															:
															<></>
													}
													{
														(values[REG_FORM_KEY.AADHAAR_CARD_NUMBER] !== undefined) ?
															<Grid
																xs={12}
															>
																<TextField
																	fullWidth
																	label={REG_FORM_LABEL.AADHAAR_CARD_NUMBER}
																	name={REG_FORM_KEY.AADHAAR_CARD_NUMBER}
																	required
																	disabled={true}
																	onChange={onChange}
																	value={values[REG_FORM_KEY.AADHAAR_CARD_NUMBER]}
																	error={errors[REG_FORM_KEY.AADHAAR_CARD_NUMBER]}
																	helperText={errors[REG_FORM_KEY.AADHAAR_CARD_NUMBER] ? HELPER_TEXT.AADHAAR_CARD_NUMBER : ''}
																	inputProps={{ pattern: RULE_PATTERN_VALUE.AADHAAR_CARD_NUMBER }}
																/>
															</Grid>
															:
															<></>
													}
												</Grid>
											</Box>
										</CardContent>
									</Card>
								</Grid>
								{
									((values[REG_FORM_KEY.IS_PLAYER] !== undefined || values[REG_FORM_KEY.IS_COACH_OR_MANAGER] !== undefined || values[REG_FORM_KEY.IS_REFEREE] !== undefined)) ?
										<Grid
											xs={12}
											md={4}
										>
											<Card sx={{ height: "100%" }} >
												<CardHeader
													subheader=""
													title="Role"
												/>
												<CardContent sx={{ pt: 0 }}>
													<Box sx={{ m: -1.5 }}>
														<Stack spacing={1}>
															{
																(values[REG_FORM_KEY.IS_PLAYER] !== undefined) ?
																	<FormControlLabel
																		control={<Checkbox onChange={onChange} checked={(playerDetails === true) ? true : false} name={REG_FORM_KEY.IS_PLAYER} />}
																		label="Player"
																	/>
																	:
																	<></>
															}
															{
																(values[REG_FORM_KEY.IS_COACH_OR_MANAGER] !== undefined) ?
																	<FormControlLabel
																		control={<Checkbox onChange={onChange} checked={(officialDetails === true) ? true : false} name={REG_FORM_KEY.IS_COACH_OR_MANAGER} />}
																		label="Coach or Manager"
																	/>
																	:
																	<></>
															}
															{
																(values[REG_FORM_KEY.IS_REFEREE] !== undefined) ?
																	<FormControlLabel
																		control={<Checkbox onChange={onChange} checked={(refereeDetails === true) ? true : false} name={REG_FORM_KEY.IS_REFEREE} />}
																		label="Referee"
																	/>
																	:
																	<></>
															}
															<input type='button' style={{ height: 0, width: 0, opacity: 0 }} ref={roleElement} />
															<Typography sx={{ mt: 2, color: "red", textAlign: "center" }} >
																{errors[REG_FORM_KEY.IS_PLAYER] ? "Provide Role" : ''}
															</Typography>
														</Stack>
													</Box>
												</CardContent>
											</Card>
										</Grid>
										:
										<></>
								}
							</Grid>
							{
								(values[REG_FORM_KEY.FATHER_NAME] !== undefined || values[REG_FORM_KEY.MOTHER_NAME] !== undefined || values[REG_FORM_KEY.PHONE_NUMBER] !== undefined) ?
									<Grid
										container
										spacing={3}
									>
										<Grid
											xs={12}
											md={12}
											lg={12}
										>
											<Card>
												<CardHeader
													subheader=""
													title="Personal Details"
												/>
												<CardContent sx={{ pt: 0 }}>
													<Box sx={{ m: -1.5 }}>
														<Grid
															container
															spacing={3}
														>
															{
																(values[REG_FORM_KEY.FATHER_NAME] !== undefined) ?
																	<Grid
																		xs={12}
																		md={4}
																	>
																		<TextField
																			fullWidth
																			label={REG_FORM_LABEL.FATHER_NAME}
																			name={REG_FORM_KEY.FATHER_NAME}
																			required
																			value={values[REG_FORM_KEY.FATHER_NAME]}
																			error={errors[REG_FORM_KEY.FATHER_NAME]}
																			helperText={HELPER_TEXT.ONLY_ALPABETS_MAX_128}
																			inputProps={{ pattern: RULE_PATTERN_VALUE.ONLY_ALPABETS_MAX_128 }}
																			onChange={onChange}
																		/>
																	</Grid>
																	:
																	<></>
															}
															{
																(values[REG_FORM_KEY.MOTHER_NAME] !== undefined) ?
																	<Grid
																		xs={12}
																		md={4}
																	>
																		<TextField
																			fullWidth
																			label={REG_FORM_LABEL.MOTHER_NAME}
																			name={REG_FORM_KEY.MOTHER_NAME}
																			required
																			value={values[REG_FORM_KEY.MOTHER_NAME]}
																			error={errors[REG_FORM_KEY.MOTHER_NAME]}
																			helperText={HELPER_TEXT.ONLY_ALPABETS_MAX_128}
																			inputProps={{ pattern: RULE_PATTERN_VALUE.ONLY_ALPABETS_MAX_128 }}
																			onChange={onChange}
																		/>
																	</Grid>
																	:
																	<></>
															}
															{
																(values[REG_FORM_KEY.PHONE_NUMBER] !== undefined) ?
																	<Grid
																		xs={12}
																		md={4}
																	>
																		<TextField
																			fullWidth
																			label={REG_FORM_LABEL.PHONE_NUMBER}
																			name={REG_FORM_KEY.PHONE_NUMBER}
																			required
																			value={values[REG_FORM_KEY.PHONE_NUMBER]}
																			error={errors[REG_FORM_KEY.PHONE_NUMBER]}
																			helperText={HELPER_TEXT.PHONE_NUMBER}
																			inputProps={{ pattern: RULE_PATTERN_VALUE.PHONE_NUMBER }}
																			onChange={onChange}
																		/>
																	</Grid>
																	:
																	<></>
															}
														</Grid>
													</Box>
												</CardContent>
											</Card>
										</Grid>
									</Grid>
									:
									<></>
							}
							{
								(playerDetails) ?
									<Grid
										container
										spacing={3}
									>
										<Grid
											xs={12}
											md={12}
											lg={12}
										>
											<Card>
												<CardHeader
													subheader=""
													title="Player Details"
												/>
												<CardContent sx={{ pt: 0 }}>
													<Box sx={{ m: -1.5 }}>
														<Grid
															container
															spacing={3}
														>
															{
																(values[REG_FORM_KEY.NATIONAL_PLAYED] !== undefined) ?
																	<Grid
																		xs={12}
																		md={4}
																	>
																		<TextField
																			fullWidth
																			label={REG_FORM_LABEL.NATIONAL_PLAYED}
																			name={REG_FORM_KEY.NATIONAL_PLAYED}
																			required
																			value={values[REG_FORM_KEY.NATIONAL_PLAYED]}
																			error={errors[REG_FORM_KEY.NATIONAL_PLAYED]}
																			helperText={HELPER_TEXT.ONLY_NUMBER_MAX_4}
																			inputProps={{ pattern: RULE_PATTERN_VALUE.ONLY_NUMBER_MAX_4 }}
																			onChange={onChange}
																		/>
																	</Grid>
																	:
																	<></>
															}
															{
																(values[REG_FORM_KEY.INTERNATIONAL_PLAYED] !== undefined) ?
																	<Grid
																		xs={12}
																		md={4}
																	>
																		<TextField
																			fullWidth
																			label={REG_FORM_LABEL.INTERNATIONAL_PLAYED}
																			name={REG_FORM_KEY.INTERNATIONAL_PLAYED}
																			required
																			value={values[REG_FORM_KEY.INTERNATIONAL_PLAYED]}
																			error={errors[REG_FORM_KEY.INTERNATIONAL_PLAYED]}
																			helperText={HELPER_TEXT.ONLY_NUMBER_MAX_4}
																			inputProps={{ pattern: RULE_PATTERN_VALUE.ONLY_NUMBER_MAX_4 }}
																			onChange={onChange}
																		/>
																	</Grid>
																	:
																	<></>
															}
														</Grid>
													</Box>
												</CardContent>
											</Card>
										</Grid>
									</Grid>
									:
									<></>
							}
							{
								(officialDetails) ?
									<Grid
										container
										spacing={3}
									>
										<Grid
											xs={12}
											md={12}
											lg={12}
										>
											<Card>
												<CardHeader
													subheader=""
													title="Official Details"
												/>
												<CardContent sx={{ pt: 0 }}>
													<Box sx={{ m: -1.5 }}>
														<Grid
															container
															spacing={3}
														>
															{
																(values[REG_FORM_KEY.NATIONAL_PARTICIPATED_AS_COACH] !== undefined) ?
																	<Grid
																		xs={12}
																		md={4}
																	>
																		<TextField
																			fullWidth
																			label={REG_FORM_LABEL.NATIONAL_PARTICIPATED_AS_COACH}
																			name={REG_FORM_KEY.NATIONAL_PARTICIPATED_AS_COACH}
																			required
																			value={values[REG_FORM_KEY.NATIONAL_PARTICIPATED_AS_COACH]}
																			error={errors[REG_FORM_KEY.NATIONAL_PARTICIPATED_AS_COACH]}
																			helperText={HELPER_TEXT.ONLY_NUMBER_MAX_4}
																			inputProps={{ pattern: RULE_PATTERN_VALUE.ONLY_NUMBER_MAX_4 }}
																			onChange={onChange}
																		/>
																	</Grid>
																	:
																	<></>
															}
															{
																(values[REG_FORM_KEY.INTERNATIONAL_PARTICIPATED_AS_COACH] !== undefined) ?
																	<Grid
																		xs={12}
																		md={4}
																	>
																		<TextField
																			fullWidth
																			label={REG_FORM_LABEL.INTERNATIONAL_PARTICIPATED_AS_COACH}
																			name={REG_FORM_KEY.INTERNATIONAL_PARTICIPATED_AS_COACH}
																			required
																			value={values[REG_FORM_KEY.INTERNATIONAL_PARTICIPATED_AS_COACH]}
																			error={errors[REG_FORM_KEY.INTERNATIONAL_PARTICIPATED_AS_COACH]}
																			helperText={HELPER_TEXT.ONLY_NUMBER_MAX_4}
																			inputProps={{ pattern: RULE_PATTERN_VALUE.ONLY_NUMBER_MAX_4 }}
																			onChange={onChange}
																		/>
																	</Grid>
																	:
																	<></>
															}
															{
																(values[REG_FORM_KEY.NATIONAL_PARTICIPATED_AS_MANAGER] !== undefined) ?
																	<Grid
																		xs={12}
																		md={4}
																	>
																		<TextField
																			fullWidth
																			label={REG_FORM_LABEL.NATIONAL_PARTICIPATED_AS_MANAGER}
																			name={REG_FORM_KEY.NATIONAL_PARTICIPATED_AS_MANAGER}
																			required
																			value={values[REG_FORM_KEY.NATIONAL_PARTICIPATED_AS_MANAGER]}
																			error={errors[REG_FORM_KEY.NATIONAL_PARTICIPATED_AS_MANAGER]}
																			helperText={HELPER_TEXT.ONLY_NUMBER_MAX_4}
																			inputProps={{ pattern: RULE_PATTERN_VALUE.ONLY_NUMBER_MAX_4 }}
																			onChange={onChange}
																		/>
																	</Grid>
																	:
																	<></>
															}
															{
																(values[REG_FORM_KEY.INTERNATIONAL_PARTICIPATED_AS_MANAGER] !== undefined) ?
																	<Grid
																		xs={12}
																		md={4}
																	>
																		<TextField
																			fullWidth
																			label={REG_FORM_LABEL.INTERNATIONAL_PARTICIPATED_AS_MANAGER}
																			name={REG_FORM_KEY.INTERNATIONAL_PARTICIPATED_AS_MANAGER}
																			required
																			value={values[REG_FORM_KEY.INTERNATIONAL_PARTICIPATED_AS_MANAGER]}
																			error={errors[REG_FORM_KEY.INTERNATIONAL_PARTICIPATED_AS_MANAGER]}
																			helperText={HELPER_TEXT.ONLY_NUMBER_MAX_4}
																			inputProps={{ pattern: RULE_PATTERN_VALUE.ONLY_NUMBER_MAX_4 }}
																			onChange={onChange}
																		/>
																	</Grid>
																	:
																	<></>
															}
														</Grid>
													</Box>
												</CardContent>
											</Card>
										</Grid>
									</Grid>
									:
									<></>
							}
							{
								(refereeDetails) ?
									<Grid
										container
										spacing={3}
									>
										<Grid
											xs={12}
											md={12}
											lg={12}
										>
											<Card>
												<CardHeader
													subheader=""
													title="Referee Details"
												/>
												<CardContent sx={{ pt: 0 }}>
													<Box sx={{ m: -1.5 }}>
														<Grid
															container
															spacing={3}
														>
															{
																(values[REG_FORM_KEY.NATIONAL_REFEREED] !== undefined) ?
																	<Grid
																		xs={12}
																		md={4}
																	>
																		<TextField
																			fullWidth
																			label={REG_FORM_LABEL.NATIONAL_REFEREED}
																			name={REG_FORM_KEY.NATIONAL_REFEREED}
																			required
																			value={values[REG_FORM_KEY.NATIONAL_REFEREED]}
																			error={errors[REG_FORM_KEY.NATIONAL_REFEREED]}
																			helperText={HELPER_TEXT.ONLY_NUMBER_MAX_4}
																			inputProps={{ pattern: RULE_PATTERN_VALUE.ONLY_NUMBER_MAX_4 }}
																			onChange={onChange}
																		/>
																	</Grid>
																	:
																	<></>
															}
															{
																(values[REG_FORM_KEY.INTERNATIONAL_REFEREED] !== undefined) ?
																	<Grid
																		xs={12}
																		md={4}
																	>
																		<TextField
																			fullWidth
																			label={REG_FORM_LABEL.INTERNATIONAL_REFEREED}
																			name={REG_FORM_KEY.INTERNATIONAL_REFEREED}
																			required
																			value={values[REG_FORM_KEY.INTERNATIONAL_REFEREED]}
																			error={errors[REG_FORM_KEY.INTERNATIONAL_REFEREED]}
																			helperText={HELPER_TEXT.ONLY_NUMBER_MAX_4}
																			inputProps={{ pattern: RULE_PATTERN_VALUE.ONLY_NUMBER_MAX_4 }}
																			onChange={onChange}
																		/>
																	</Grid>
																	:
																	<></>
															}
															{
																(values[REG_FORM_KEY.REFREEE_LEVEL] !== undefined) ?
																	<Grid
																		xs={12}
																		md={4}
																	>
																		<TextField
																			fullWidth
																			label={REG_FORM_LABEL.REFREEE_LEVEL}
																			name={REG_FORM_KEY.REFREEE_LEVEL}
																			required
																			select
																			SelectProps={{ native: true }}
																			value={values.refereeLevel}
																			onChange={onChange}
																		>
																			{REFREEE_LEVELS.map((option) => (
																				<option
																					key={option.value}
																					value={option.value}
																				>
																					{option.label}
																				</option>
																			))}
																		</TextField>
																	</Grid>
																	:
																	<></>
															}
															{
																(values[REG_FORM_KEY.REFREEE_PROOF] !== undefined) ?
																	<Grid
																		xs={12}
																		md={4}
																	>
																		<TextField
																			fullWidth
																			type="file"
																			label={REG_FORM_LABEL.REFREEE_PROOF}
																			name={REG_FORM_KEY.REFREEE_PROOF}
																			error={errors[REG_FORM_KEY.REFREEE_PROOF]}
																			helperText={HELPER_TEXT.IMAGE}
																			required
																			InputLabelProps={{ shrink: true }}
																			inputProps={{ accept: ACCEPT_FILE_TYPE.IMAGE }}
																			onChange={onChange}
																		/>
																	</Grid>
																	:
																	<></>
															}
														</Grid>
													</Box>
												</CardContent>
											</Card>
										</Grid>
									</Grid>
									:
									<></>
							}
							{
								(values[REG_FORM_KEY.PASSPORT_NUMBER] !== undefined || values[REG_FORM_KEY.PASSPORT_EXPIRY_MONTH_AND_YEAR] !== undefined || values[REG_FORM_KEY.PASSPORT_ISSUE_PLACE] !== undefined || values[REG_FORM_KEY.PASSPORT_COPY] !== undefined) ?
									<Grid
										container
										spacing={3}
									>
										<Grid
											xs={12}
											md={12}
											lg={12}
										>
											<Card>
												<CardHeader
													subheader=""
													title="Passport Details"
												/>
												<CardContent sx={{ pt: 0 }}>
													<Box sx={{ m: -1.5 }}>
														<Grid
															container
															spacing={3}
														>
															{
																(values[REG_FORM_KEY.PASSPORT_NUMBER] !== undefined) ?
																	<Grid
																		xs={12}
																		md={4}
																	>
																		<TextField
																			fullWidth
																			label={REG_FORM_LABEL.PASSPORT_NUMBER}
																			name={REG_FORM_KEY.PASSPORT_NUMBER}
																			error={errors[REG_FORM_KEY.PASSPORT_NUMBER]}
																			helperText={HELPER_TEXT.PASSPORT_NUMBER}
																			onChange={onChange}
																			inputProps={{ pattern: RULE_PATTERN_VALUE.PASSPORT_NUMBER }}
																			required={passportDetailsFilled}
																		/>
																	</Grid>
																	:
																	<></>
															}
															{
																(values[REG_FORM_KEY.PASSPORT_EXPIRY_MONTH_AND_YEAR] !== undefined) ?
																	<Grid
																		xs={12}
																		md={4}
																	>
																		<TextField
																			fullWidth
																			label={REG_FORM_LABEL.PASSPORT_EXPIRY_MONTH_AND_YEAR}
																			name={REG_FORM_KEY.PASSPORT_EXPIRY_MONTH_AND_YEAR}
																			error={errors[REG_FORM_KEY.PASSPORT_EXPIRY_MONTH_AND_YEAR]}
																			helperText={HELPER_TEXT.DATE}
																			inputProps={{ pattern: RULE_PATTERN_VALUE.DD_MM_YYYY }}
																			required={passportDetailsFilled}
																			onChange={onChange}
																		/>
																	</Grid>
																	:
																	<></>
															}
															{
																(values[REG_FORM_KEY.PASSPORT_ISSUE_PLACE] !== undefined) ?
																	<Grid
																		xs={12}
																		md={4}
																	>
																		<TextField
																			fullWidth
																			label={REG_FORM_LABEL.PASSPORT_ISSUE_PLACE}
																			name={REG_FORM_KEY.PASSPORT_ISSUE_PLACE}
																			error={errors[REG_FORM_KEY.PASSPORT_ISSUE_PLACE]}
																			helperText={HELPER_TEXT.ONLY_ALPABETS_MAX_128}
																			inputProps={{ pattern: RULE_PATTERN_VALUE.ONLY_ALPABETS_MAX_128 }}
																			required={passportDetailsFilled}
																			onChange={onChange}
																		/>
																	</Grid>
																	:
																	<></>
															}
															{
																(values[REG_FORM_KEY.PASSPORT_COPY] !== undefined) ?
																	<Grid
																		xs={12}
																		md={4}
																	>
																		<TextField
																			fullWidth
																			type="file"
																			label={REG_FORM_LABEL.PASSPORT_COPY}
																			name={REG_FORM_KEY.PASSPORT_COPY}
																			error={errors[REG_FORM_KEY.PASSPORT_COPY]}
																			helperText={HELPER_TEXT.IMAGE}
																			InputLabelProps={{ shrink: true }}
																			inputProps={{ accept: ACCEPT_FILE_TYPE.IMAGE }}
																			required={passportDetailsFilled}
																			onChange={onChange}
																		/>
																	</Grid>
																	:
																	<></>
															}
														</Grid>
													</Box>
												</CardContent>
											</Card>
										</Grid>
									</Grid>
									:
									<></>
							}
							{
								(values[REG_FORM_KEY.GOVERNMENT_PROOF] !== undefined) ?
									<Grid
										container
										spacing={3}
									>
										<Grid
											xs={12}
											md={12}
											lg={12}
										>
											<Card>
												<CardHeader
													subheader=""
													title="Documents"
												/>
												<CardContent sx={{ pt: 0 }}>
													<Box sx={{ m: -1.5 }}>
														<Grid
															container
															spacing={3}
														>
															{
																(values[REG_FORM_KEY.GOVERNMENT_PROOF] !== undefined) ?
																	<Grid
																		xs={12}
																		md={6}
																	>
																		<TextField
																			fullWidth
																			type="file"
																			inputProps={{ accept: ACCEPT_FILE_TYPE.IMAGE }}
																			label={REG_FORM_LABEL.GOVERNMENT_PROOF}
																			name={REG_FORM_KEY.GOVERNMENT_PROOF}
																			error={errors[REG_FORM_KEY.GOVERNMENT_PROOF]}
																			helperText={HELPER_TEXT.GOVERNMENT_PROOF}
																			InputLabelProps={{ shrink: true }}
																			required
																		/>
																	</Grid>
																	:
																	<></>
															}
														</Grid>
													</Box>
												</CardContent>
											</Card>
										</Grid>
									</Grid>
									:
									<></>
							}
							{
								(values[REG_FORM_KEY.AADHAAR_XML] !== undefined || values[REG_FORM_KEY.SHARE_CODE] !== undefined) ?
									<Grid
										container
										spacing={3}
									>
										<Grid
											xs={12}
											md={12}
											lg={12}
										>
											<Card>
												<CardHeader
													subheader=""
													title="Offline Aadhaar"
												/>
												<CardContent sx={{ pt: 0 }}>
													<Box sx={{ m: -1.5 }}>
														<Grid
															container
															spacing={3}
														>
															{
																(values[REG_FORM_KEY.AADHAAR_XML] !== undefined) ?
																	<Grid
																		xs={12}
																		md={6}
																	>
																		<TextField
																			fullWidth
																			type="file"
																			label={REG_FORM_LABEL.AADHAAR_XML}
																			name={REG_FORM_KEY.AADHAAR_XML}
																			error={errors[REG_FORM_KEY.AADHAAR_XML]}
																			helperText={HELPER_TEXT.AADHAAR_XML}
																			InputLabelProps={{ shrink: true }}
																			inputProps={{ accept: ACCEPT_FILE_TYPE.ZIP }}
																			required
																		/>
																	</Grid>
																	:
																	<></>
															}
															{
																(values[REG_FORM_KEY.SHARE_CODE] !== undefined) ?
																	<Grid
																		xs={12}
																		md={6}
																	>
																		<TextField
																			fullWidth
																			label={REG_FORM_LABEL.SHARE_CODE}
																			name={REG_FORM_KEY.SHARE_CODE}
																			required
																			error={errors[REG_FORM_KEY.SHARE_CODE]}
																			helperText={HELPER_TEXT.SHARE_CODE}
																			inputProps={{ pattern: RULE_PATTERN_VALUE.FOUR_NUMBERS }}
																			onChange={onChange}
																		/>
																	</Grid>
																	:
																	<></>
															}
															<Grid
																xs={12}
																px={4}
															>
																<Box sx={{ fontSize: 14, color: "#6C737F" }} >
																	The process of generating Aadhaar Offline e-KYC is explained below:
																	<ul>
																		<li>Go to URL <a rel="noreferrer" target="_blank" href='https://myaadhaar.uidai.gov.in/offline-ekyc'>https://myaadhaar.uidai.gov.in/offline-ekyc</a></li>
																		<li>Enter ‘Aadhaar Number’ or ‘VID’ and enter mentioned ‘Security Code’ in screen, then click on ‘Send OTP’. The OTP will be sent to the registered Mobile Number for the given Aadhaar number or VID. OTP will be available on m-Aadhaar mobile Application of UIDAI. Enter the OTP received. Enter a Share Code which be the password for the ZIP file and click on ‘Download’ button</li>
																	</ul>
																</Box>
															</Grid>
														</Grid>
													</Box>
												</CardContent>
											</Card>
										</Grid>
									</Grid>
									:
									<></>
							}
							<Grid
								container
								spacing={3}
							>
								<Grid
									xs={12}
									md={12}
									lg={12}
								>
									<Card>
										<CardHeader
											subheader=""
											title="Terms and Conditions"
										/>
										<CardContent sx={{ pt: 0 }}>
											<Box sx={{ m: -1.5 }}>
												<Grid
													container
													spacing={3}
												>
													<Grid
														xs={12}
														pl={3}
													>
														<FormControlLabel
															control={<Checkbox required />}
															label={
																<span>
																	I, as a representative of the State Association, certify that the information provided on behalf of the player/official/referee is true and verified. The player/official/referee agrees to comply with all the rules and regulations of Sepaktakraw and adhere to the terms and conditions set forth by STFI. Please be aware that the STFI UID may be revoked at any time if the player/official/referee fails to comply with STFI rules or if any of the submitted information is found to be incorrect
																</span>
															}
														/>
													</Grid>
													<Grid
														xs={12}
														pl={3}
													>
														<FormControlLabel
															control={<Checkbox required />}
															label={
																<span>
																	I agree to the {" "}
																	<a
																		href={TERMS_AND_CONDITION_LINK}
																		target="_blank"
																		rel="noopener noreferrer"
																	>
																		terms and conditions
																	</a>
																</span>
															}
														/>
													</Grid>
												</Grid>
											</Box>
										</CardContent>
									</Card>
								</Grid>
							</Grid>
							<Box sx={{ mt: 5, textAlign: "center" }}>
								<Button type='submit' variant='contained'>Submit</Button>
							</Box>
						</form>
						:
						<></>
			}

			<Info {...info} />
			<Snackbar {...snackbar} />
			<Backdrop open={backdrop} />
			<Dialog {...dialog} />
		</>
	);
};
