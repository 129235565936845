import {
  Box,
  Divider,
  Drawer,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery
} from '@mui/material';
import { Logo } from '../../components/logo';
import { SideNavItem } from './side-nav-item';
import { Link, useLocation } from 'react-router-dom';
import { USER_ROLE } from '../../constant';
import { insertSpace } from '../../utils';
import { NewsFeedSvg } from '../../assets/menu/NewsFeedSvg';
import { UsersSvg } from '../../assets/menu/UsersSvg';
import { ApprovedSvg } from '../../assets/menu/ApprovedSvg';
import { StatesSvg } from '../../assets/menu/StatesSvg';
import { NewSvg } from '../../assets/menu/NewSvg';
import { DashboardSvg } from '../../assets/menu/DashboardSvg';
import { GallerySvg } from '../../assets/menu/GallerySvg';
import { ProfileSvg } from '../../assets/menu/ProfileSvg';
import { TournamentSvg } from '../../assets/menu/TournamentSvg';

export const SideNav = (props) => {

  const { open, onClose, user } = props;
  const location = useLocation();
  const pathname = location.pathname+location.search;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const menus = [];

  if(user.passportSizePhoto) {
    menus.push({
      title: 'My Profile',
      path: '/profile',
      icon: (
        <SvgIcon fontSize="small">
          <ProfileSvg />
        </SvgIcon>
      )
    });
  }

  menus.push({
    title: 'News and Updates',
    path: '/newsfeed',
    icon: (
      <SvgIcon fontSize="small">
        <NewsFeedSvg />
      </SvgIcon>
    )
  },
  {
    title: 'Gallery',
    path: '/gallery',
    icon: (
      <SvgIcon fontSize="small">
        <GallerySvg />
      </SvgIcon>
    )
  }
  );

  if(user.role === USER_ROLE.STATE_ADMIN || user.role === USER_ROLE.ADMIN) {

    menus.push({
      title: 'Dashboard',
      path: '/dashboard',
      icon: (
        <SvgIcon fontSize="small">
          <DashboardSvg />
        </SvgIcon>
      )
    });
  }

  if(user.role === USER_ROLE.ADMIN) {

    menus.push({
      title: 'Users',
      path: '/users',
      icon: (
        <SvgIcon fontSize="small">
          <UsersSvg />
        </SvgIcon>
      )
    });

    menus.push({
      title: 'States',
      path: '/states',
      icon: (
        <SvgIcon fontSize="small">
          <StatesSvg />
        </SvgIcon>
      )
    },
    {
      title: 'Registrations',
      path: '/registration/view',
      icon: (
        <SvgIcon fontSize="small">
          <ApprovedSvg />
        </SvgIcon>
      )
    },
    {
      title: 'Tournament',
      path: '/tournament',
      icon: (
        <SvgIcon fontSize="small">
          <TournamentSvg />
        </SvgIcon>
      )
    }
    );
  }

  if(user.role === USER_ROLE.STATE_ADMIN || user.role === USER_ROLE.ADMIN) {

    let submenu = [];
    if(user.role === USER_ROLE.STATE_ADMIN) {
      submenu = [
        {
          title: 'New / Update',
          path: '/registration/form',
          icon: (
            <SvgIcon fontSize="small">
              <NewSvg />
            </SvgIcon>
          )
        },
        {
          title: 'View All',
          path: '/registration/view',
          icon: (
            <SvgIcon fontSize="small">
              <ApprovedSvg />
            </SvgIcon>
          )
        }
      ];
      menus.push({
        title: 'Registrations',
        submenu: submenu
      });
    }

    /*submenu = [
      ...submenu,
      {
        title: 'View',
        path: '/registration/view',
        icon: (
          <SvgIcon fontSize="small">
            <ApprovedSvg />
          </SvgIcon>
        )
      },
      {
        title: 'Pending',
        path: '/registration/view?status=P',
        icon: (
          <SvgIcon fontSize="small">
            <PendingSvg />
          </SvgIcon>
        )
      },
      {
        title: 'Rejected',
        path: '/registration/view?status=R',
        icon: (
          <SvgIcon fontSize="small">
            <RejectedSvg />
          </SvgIcon>
        )
      }
    ];
    */
  }

  const content = (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              display: 'inline-flex'
            }} 
          >
            <Box
              component={Link}
              href="/"
              sx={{
                display: 'inline-flex',
                height: 32,
                width: 32
              }}
            >
              <Logo />
              
            </Box>
            <Typography
              color="inherit"
              variant="subtitle1"
              sx={{
                paddingLeft: "0.3em",
                fontSize:"1.3em"
              }} 
            >
              Sepaktakraw
              <span style={{fontSize:"0.6em"}}> India</span>
            </Typography>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.04)',
              borderRadius: 1,
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'space-between',
              mt: 2,
              p: '12px'
            }}
          >
            <div>
              <Typography
                color="inherit"
                variant="subtitle1"
              >
                {user.name}
              </Typography>
              <Typography
                color="neutral.400"
                variant="body2"
              >
                {
                  (user.stateName)?
                  `${insertSpace(user.stateName.toLowerCase())} ${(user.role === USER_ROLE.ADMIN)?"- Admin":(user.role === USER_ROLE.STATE_ADMIN)?"- State Admin":""}`
                  :
                  ``
                }
              </Typography>
            </div>
          </Box>
        </Box>
        <Divider sx={{ borderColor: 'neutral.700' }} />
        <Box
          component="nav"
          sx={{
            flexGrow: 1,
            px: 2,
            py: 3
          }}
        >
          <Stack
            component="ul"
            spacing={0.5}
            sx={{
              listStyle: 'none',
              p: 0,
              m: 0
            }}
          >
            {menus.map((item) => {

              if(item.submenu) {

                return (
                  <Box key={item.title} >
                    <Divider sx={{ borderColor: 'neutral.700' }} />
                    <Box
                      sx={{
                        p: '0.6em'
                      }}
                    >
                      <Typography
                        color="neutral.400"
                        variant="body2"
                      >
                        {item.title}
                      </Typography>
                    </Box>
                    {
                      item.submenu.map((item1) => {

                        const active = item1.path ? (pathname.indexOf(item1.path) !== -1) : false;
                          return (
                            <SideNavItem
                              active={active}
                              disabled={item1.disabled}
                              external={item1.external}
                              icon={item1.icon}
                              key={item1.title}
                              path={item1.path}
                              title={item1.title}
                            />
                          );
                      })
                    }
                  </Box>
                );
              }
              const active = item.path ? (pathname.indexOf(item.path) !== -1) : false;

              return (
                <SideNavItem
                  active={active}
                  disabled={item.disabled}
                  external={item.external}
                  icon={item.icon}
                  key={item.title}
                  path={item.path}
                  title={item.title}
                />
              );
            })}
            
          </Stack>
        </Box>
        <Divider sx={{ borderColor: 'neutral.700' }} />
      </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.800',
            color: 'common.white',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.800',
          color: 'common.white',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};