import React, { useCallback, useState } from 'react'
import { RULE_PATTERN_VALUE } from '../../constant';
import { Avatar, Box, Button, Card, CardContent, CardHeader, Unstable_Grid2 as Grid, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { API_STFI } from '../../api/stfi';
import { TOURNAMENT_FORM_KEY } from './TournamentForm';

const Member = (props) => {

    const { role, passportSizePhoto, name, playerRegistrationId, officialRegistrationId, refereeRegistrationId, index, dispatch } = props;

    const removeMember = () => {
        dispatch({ type: "REMOVE_MEMBER", role: role, registrationId: (playerRegistrationId || officialRegistrationId || refereeRegistrationId), data: props });
    };

    return (
        <Card key={index} sx={{ width: 250, p: 1, m: 1 }}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Avatar alt={name} src={passportSizePhoto} sx={{ width: 70, height: 70, m: 1 }} />
                <Typography gutterBottom component="div" textAlign={"center"}>
                    {name}
                </Typography>
                <Typography gutterBottom component="div">
                    {playerRegistrationId || officialRegistrationId || refereeRegistrationId}
                </Typography>
                <Button size="small" color="primary" onClick={removeMember}>
                    Remove
                </Button>
            </Box>
        </Card>
    );
}

const AddMember = ({ tournament, role, header, dispatch }) => {

    const [registrationId, setRegistrationId] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleChange = useCallback(
        (event) => {

            const { value } = event.target;

            setError("");
            setRegistrationId(value);
        },
        []
    );

    function getRoleFromRegId(id) {
        for (let i = id.length - 1; i >= 0; i--) {
            const char = id[i];
            if (isNaN(char)) {
                return char
            }
        }
        return "";
    }

    const getDetails = useCallback(async (registrationId) => {

        setError("");

        const newRole = getRoleFromRegId(registrationId);

        if (newRole !== role) {

            if (newRole === "O" && (role === "M" || role === "C")) {

            }
            else {
                setError("Select a valid member");
                return;
            }
        }

        const isValid = new RegExp(RULE_PATTERN_VALUE.REG_ID).test(registrationId);
        if (!isValid) {
            // Display error message (e.g., using a state variable)
            setError("Invalid Registration ID format");
            return;
        }

        try {

            const response = await API_STFI.GET_REGISTRATION_ID(registrationId);

            if (response.status === 200) {
                dispatch({ type: "ADD_MEMBER", role: role, registrationId: registrationId, data: response.data[0] });
            }
            else {
                setError("Enter a valid registration ID");
            }

        } catch (error) {

            if (error.response) {

                if (error.response.status === 401) {
                    navigate("/");
                }

                setError("Enter a valid registration ID");
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Server responded with an error:', error.response.status, error.response);


            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received from the server');
                setError("Enter a valid registration ID");
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error setting up the request:', error.message);
                setError(error.message);
            }
        }
    }, [navigate, dispatch, role]);

    const RenderMember = ({ header, name, tournament }) => {

        var arr = [];
        var events = tournament[TOURNAMENT_FORM_KEY.INTERNATIONAL];
        for (let i = 0; i < events.length; i++) {
            if (events[i].role === role) {
                arr = events[i][name]
                break;
            }
        }

        if (arr.length) {
            return (
                <>
                    <Typography variant='h6' p={1}>{header}</Typography>
                    <Box sx={{ display: "flex" }} >
                        {
                            arr.map((d, index) => {
                                return (<Member {...d} role={role} index={index} dispatch={dispatch} />)
                            })
                        }
                    </Box>
                </>
            )
        }
        else return (<></>);
    };

    const helperText = () => {

        var newRole = role;
        if (role === "M" || role === "C") {
            newRole = "O";
        }

        return "Example TN"+newRole+"001";
    };

    return (
        <Card sx={{ mt: 2 }} >
            <CardHeader
                subheader={""}
                title={header}
            />
            <CardContent sx={{ pt: 0 }}>
                <Box sx={{ m: -1.5 }}>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            md={4}
                            
                        >
                            <TextField
                                fullWidth
                                label={"Registration ID"}
                                name={"registrationId"}
                                required={(role === "R")?false:true}
                                onChange={handleChange}
                                value={registrationId}
                                helperText={helperText()}
                                inputProps={{ pattern: RULE_PATTERN_VALUE.REG_ID }}
                            />
                        </Grid>
                        <Grid
                            md={4}
                            
                        >
                            <Box sx={{ textAlign: "center" }}>
                                <Button onClick={() => { getDetails(registrationId) }} variant='outlined'>Add</Button>
                            </Box>
                        </Grid>
                        {
                            (error) ?
                                <Grid
                                    md={12}
                                    
                                >
                                    <Typography sx={{ color: "red", p: 1 }} >{error}</Typography>
                                </Grid>
                                :
                                <></>
                        }

                        <Grid
                            md={12}
                            
                        >
                            <RenderMember header={"Male"} tournament={tournament} name={"qualifiedMenDetails"} />
                            <RenderMember header={"Female"} tournament={tournament} name={"qualifiedWomenDetails"} />
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    )
}

function TournamentMembers(props) {

    return (
        <>
            <AddMember role={"P"} header={"Add Players"} {...props} />
            <AddMember role={"C"} header={"Add Coaches"} {...props} />
            <AddMember role={"M"} header={"Add Managers"} {...props} />
            <AddMember role={"R"} header={"Add Referees"} {...props} />
        </>
    );
}

export default TournamentMembers