import { Avatar, Box, Card, CardContent, CardHeader, Chip, CircularProgress, Unstable_Grid2 as Grid, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { TOURNAMENT_FORM_KEY } from './TournamentForm'
import { ERROR_STR, infoProps, TOURNAMENT_VIEW_STYLE } from '../../constant'
import FilePreviewBtn from '../../components/FilePreviewBtn'
import { API_STFI } from '../../api/stfi'
import { useNavigate } from 'react-router-dom'
import { sortByKey } from '../../utils'
import Info from '../../components/Info'

function TournamentFormView({ data }) {

    console.log(data);

    const [states, setStates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState(infoProps.init());
    const navigate = useNavigate();

    const convertToObj = (arr) => {
        var obj = {};
        arr.forEach((v) => obj[v.stateCode] = v.stateName);
        return obj;
    };

    const fetchData = useCallback(async () => {

        try {
            // Authenticate the google token
            const response = await API_STFI.STATES();
            setStates(convertToObj(response.data, "stateName"));

            // Hide loader
            setLoading(false);

            setInfo(infoProps.close());

        } catch (error) {

            navigate("/");
            // Hide loader
            setLoading(false);
            if (error.response) {
                console.error('Server responded with an error:', error.response.status, error.response);
                setInfo(infoProps.error(error.response.statusText));
            } else if (error.request) {
                console.error('No response received from the server');
                setInfo(infoProps.error(ERROR_STR.NO_RESPONSE));
            } else {
                console.error('Error setting up the request:', error.message);
                setInfo(infoProps.error(ERROR_STR.STD));
            }
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const EventDetails = ({ event, index }) => {

        if (!event.selected) return (<></>);

        return (
            <Card key={index} sx={{ mb: 2 }} >
                <CardHeader
                    subheader={""}
                    title={event.label}
                />
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                        <Grid
                            container
                            spacing={3}
                        >
                            {
                                (event.maximumNumberOfPlayers) ?
                                    <Grid
                                        xs={12}
                                        sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                    >
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                            Maximum Number Of Player
                                        </Typography>
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.value} >
                                            {event.maximumNumberOfPlayers}
                                        </Typography>
                                    </Grid>
                                    :
                                    <></>
                            }
                            {
                                (event.maximumNumberOfCoaches) ?
                                    <Grid
                                        xs={12}
                                        sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                    >
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                            Maximum Number Of Coaches
                                        </Typography>
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.value} >
                                            {event.maximumNumberOfCoaches}
                                        </Typography>
                                    </Grid>
                                    :
                                    <></>
                            }
                            {
                                (event.maximumNumberOfManagers) ?
                                    <Grid
                                        xs={12}
                                        sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                    >
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                            Maximum Number Of Manager
                                        </Typography>
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.value} >
                                            {event.maximumNumberOfManagers}
                                        </Typography>
                                    </Grid>
                                    :
                                    <></>
                            }
                            {
                                (event.qualifiedMaleStateCodes.length) ?
                                    <Grid
                                        xs={12}
                                        sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                    >
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                            Qualified Male States ({event.qualifiedMaleStateCodes.length})
                                        </Typography>
                                        {
                                            event.qualifiedMaleStateCodes.map((state, j) => {
                                                return (<Chip color="primary" sx={{ mr: 1, mt: 1 }} key={j} label={states[state]} />)
                                            })
                                        }
                                    </Grid>
                                    :
                                    <></>
                            }
                            {
                                (event.qualifiedFemaleStateCodes.length) ?
                                    <Grid
                                        xs={12}
                                        sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                    >
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                            Qualified Female States - ({event.qualifiedFemaleStateCodes.length})
                                        </Typography>
                                        {
                                            event.qualifiedFemaleStateCodes.map((state, j) => {
                                                return (<Chip color="primary" sx={{ mr: 1, mt: 1 }} key={j} label={states[state]} />)
                                            })
                                        }
                                    </Grid>
                                    :
                                    <></>
                            }
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        );
    };

    const MemberDetails = ({ role, index }) => {

        const Member = (props) => {

            const { passportSizePhoto, name, playerRegistrationId, officialRegistrationId, refereeRegistrationId, index } = props;

            return (
                <Card key={index} sx={{ width: 250, p: 1, m: 1 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <Avatar alt={name} src={passportSizePhoto} sx={{ width: 70, height: 70, m: 1 }} />
                        <Typography gutterBottom component="div" textAlign={"center"}>
                            {name}
                        </Typography>
                        <Typography gutterBottom component="div">
                            {playerRegistrationId || officialRegistrationId || refereeRegistrationId}
                        </Typography>
                    </Box>
                </Card>
            );
        }

        return (
            <Card key={index} sx={{ mb: 2 }} >
                <CardHeader
                    subheader={""}
                    title={(role.role === "P") ? "Player" : (role.role === "C") ? "Coach" : (role.role === "M") ? "Manager" : "Referee"}
                />
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                        <Grid
                            container
                            spacing={3}
                        >
                            {
                                (role.qualifiedMenDetails && role.qualifiedMenDetails.length) ?
                                    <Grid
                                        xs={12}
                                        sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                    >
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                            Male - ({role.qualifiedMenDetails.length})
                                        </Typography>
                                        <Box sx={{display:"flex"}} >
                                        {
                                            role.qualifiedMenDetails.map((data, j) => {
                                                return (<Member key={j} {...data} />)
                                            })
                                        }
                                        </Box>
                                    </Grid>
                                    :
                                    (role.qualifiedMen && role.qualifiedMen.length) ?
                                    <Grid
                                        xs={12}
                                        sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                    >
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                            Male - ({role.qualifiedMen.length})
                                        </Typography>
                                        <Box sx={{display:"flex"}} >
                                        {
                                            role.qualifiedMen.map((data, j) => {
                                                return (<Chip color="primary" sx={{ mr: 1, mt: 1 }} key={j} label={data} />)
                                            })
                                        }
                                        </Box>
                                    </Grid>
                                    :
                                    <></>
                        
                            }
                            {
                                (role.qualifiedWomenDetails && role.qualifiedWomenDetails.length) ?
                                    <Grid
                                        xs={12}
                                        sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                    >
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                            Female - ({role.qualifiedWomenDetails.length})
                                        </Typography>
                                        <Box sx={{display:"flex"}} >
                                        {
                                            role.qualifiedWomenDetails.map((data, j) => {
                                                return (<Member key={j} {...data} />)
                                            })
                                        }
                                        </Box>
                                    </Grid>
                                    :
                                    (role.qualifiedWomen && role.qualifiedWomen.length) ?
                                    <Grid
                                        xs={12}
                                        sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                    >
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                            Female - ({role.qualifiedWomen.length})
                                        </Typography>
                                        <Box sx={{display:"flex"}} >
                                        {
                                            role.qualifiedWomen.map((data, j) => {
                                                return (<Chip color="primary" sx={{ mr: 1, mt: 1 }} key={j} label={data} />)
                                            })
                                        }
                                        </Box>
                                    </Grid>
                                    :
                                    <></>
                            }

                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        );
    };

    return (
        <>
            {
                (loading) ?
                    <Box sx={{ width: '100%', height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress />
                    </Box>
                    :
                    (info.open) ?
                        <Info {...info} />
                        :
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                md={12}
                            >
                                <Card sx={{ height: "100%" }}>
                                    <CardHeader
                                        subheader={""}
                                        title={"Basic Details"}
                                    />
                                    <CardContent sx={{ pt: 0 }}>
                                        <Box sx={{ m: -1.5 }}>
                                            <Grid
                                                container
                                                spacing={3}
                                            >
                                                {
                                                    (data[TOURNAMENT_FORM_KEY.CATEGORY]) ?
                                                        <Grid
                                                            xs={12}
                                                            sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                                        >
                                                            <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                                                Category
                                                            </Typography>
                                                            <Typography sx={TOURNAMENT_VIEW_STYLE.value} >
                                                                {(data[TOURNAMENT_FORM_KEY.CATEGORY] === "N") ? "National" : "International"}
                                                            </Typography>
                                                        </Grid>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    (data[TOURNAMENT_FORM_KEY.NAME]) ?
                                                        <Grid
                                                            xs={12}
                                                            sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                                        >
                                                            <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                                                Name
                                                            </Typography>
                                                            <Typography sx={TOURNAMENT_VIEW_STYLE.value} >
                                                                {data[TOURNAMENT_FORM_KEY.NAME]}
                                                            </Typography>
                                                        </Grid>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    (data[TOURNAMENT_FORM_KEY.VENUE]) ?
                                                        <Grid
                                                            xs={12}
                                                            sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                                        >
                                                            <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                                                Venue
                                                            </Typography>
                                                            <Typography sx={TOURNAMENT_VIEW_STYLE.value} >
                                                                {data[TOURNAMENT_FORM_KEY.VENUE]}
                                                            </Typography>
                                                        </Grid>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    (data[TOURNAMENT_FORM_KEY.START_DATE]) ?
                                                        <Grid
                                                            xs={12}
                                                            sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                                        >
                                                            <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                                                State Date
                                                            </Typography>
                                                            <Typography sx={TOURNAMENT_VIEW_STYLE.value} >
                                                                {data[TOURNAMENT_FORM_KEY.START_DATE]}
                                                            </Typography>
                                                        </Grid>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    (data[TOURNAMENT_FORM_KEY.END_DATE]) ?
                                                        <Grid
                                                            xs={12}
                                                            sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                                        >
                                                            <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                                                End Date
                                                            </Typography>
                                                            <Typography sx={TOURNAMENT_VIEW_STYLE.value} >
                                                                {data[TOURNAMENT_FORM_KEY.END_DATE]}
                                                            </Typography>
                                                        </Grid>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    (data[TOURNAMENT_FORM_KEY.REG_STARTING_DATE]) ?
                                                        <Grid
                                                            xs={12}
                                                            sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                                        >
                                                            <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                                                Registration Starting Date
                                                            </Typography>
                                                            <Typography sx={TOURNAMENT_VIEW_STYLE.value} >
                                                                {data[TOURNAMENT_FORM_KEY.REG_STARTING_DATE]}
                                                            </Typography>
                                                        </Grid>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    (data[TOURNAMENT_FORM_KEY.REG_CLOSING_DATE]) ?
                                                        <Grid
                                                            xs={12}
                                                            sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                                        >
                                                            <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                                                Registration Closing Date
                                                            </Typography>
                                                            <Typography sx={TOURNAMENT_VIEW_STYLE.value} >
                                                                {data[TOURNAMENT_FORM_KEY.REG_CLOSING_DATE]}
                                                            </Typography>
                                                        </Grid>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    (data[TOURNAMENT_FORM_KEY.AGE_LIMIT]) ?
                                                        <Grid
                                                            xs={12}
                                                            sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                                        >
                                                            <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                                                Age limit
                                                            </Typography>
                                                            <Typography sx={TOURNAMENT_VIEW_STYLE.value} >
                                                                {data[TOURNAMENT_FORM_KEY.AGE_LIMIT]}
                                                            </Typography>
                                                        </Grid>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    (data[TOURNAMENT_FORM_KEY.OFFICIAL_DOC]) ?
                                                        <Grid
                                                            xs={12}
                                                            md={6}
                                                        >
                                                            <FilePreviewBtn buttonLabel={"Offical Document"} url={data[TOURNAMENT_FORM_KEY.OFFICIAL_DOC]} />
                                                        </Grid>
                                                        :
                                                        <></>
                                                }
                                            </Grid>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid
                                md={12}
                            >
                                {
                                    (data[TOURNAMENT_FORM_KEY.CATEGORY] === "N") ?
                                        data[TOURNAMENT_FORM_KEY.EVENTS].map((event, i) => {
                                            return (<EventDetails event={event} index={i} />)
                                        })
                                        :
                                        data[TOURNAMENT_FORM_KEY.INTERNATIONAL].map((role, i) => {
                                            return (<MemberDetails role={role} index={i} />)
                                        })
                                }
                            </Grid>
                        </Grid>
            }
        </>
    )
}

export default TournamentFormView