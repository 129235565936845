import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { ERROR_STR, TOURNAMENT_CATEGORY, dialogButtonsProps, dialogProps, infoProps, snackbarProps } from '../../constant';
import { API_STFI } from '../../api/stfi';
import Info from '../../components/Info';
import Snackbar from '../../components/Snackbar';
import Backdrop from '../../components/Backdrop';
import Dialog from '../../components/Dialog';

function applyPagination(documents, page, rowsPerPage) {
  return documents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

const useCustomers = (data, page, rowsPerPage) => {
  return useMemo(
    () => {
      return applyPagination(data, page, rowsPerPage);
    },
    [data, page, rowsPerPage]
  );
};

const TournamentView = ({ setSelectedTournament }) => {

  const [loading, setLoading] = useState(true);

  const [backdrop, setBackdrop] = useState(false);

  const [dialog, setDialog] = useState(dialogProps.init());

  const [snackbar, setSnackbar] = useState(snackbarProps.init());
  snackbar.hide = () => {
    setSnackbar(snackbarProps.hide());
  };

  const [info, setInfo] = useState(infoProps.init());

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const tableData = useCustomers(data, page, rowsPerPage);

  const handlePageChange = useCallback(
    (event, value) => {
      setPage(value);
    },
    []
  );

  const handleRowsPerPageChange = useCallback(
    (event) => {
      setRowsPerPage(event.target.value);
    },
    []
  );

  const fetchData = async () => {
    try {

      var response = await API_STFI.TOURNAMENT_VIEW();

      setData(response.data);

      // Hide loader
      setLoading(false);

      setInfo(infoProps.close());

    } catch (error) {

      // Hide loader
      setLoading(false);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Server responded with an error:', error.response.status, error.response);
        setInfo(infoProps.error(error.response.statusText));
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received from the server');
        setInfo(infoProps.error(ERROR_STR.NO_RESPONSE));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error setting up the request:', error.message);
        setInfo(infoProps.error(ERROR_STR.STD));
      }
    }
  }

  const onDelete = async ({ id, name }) => {

    var buttons = [];
    buttons.push(dialogButtonsProps.init("Delete", async () => {

      setDialog(dialogProps.close());
      setBackdrop(true);

      try {

        // Authenticate the google token
        const response = await API_STFI.TOURNAMENT_DELETE(id);
        console.log(response);

        // Hide loader
        setBackdrop(false);

        fetchData();

      } catch (error) {

        // Hide loader
        setBackdrop(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Server responded with an error:', error.response.status, error.response);
          setSnackbar(snackbarProps.error(error.response.statusText));
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received from the server');
          setSnackbar(snackbarProps.error(ERROR_STR.NO_RESPONSE));
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error setting up the request:', error.message);
          setSnackbar(snackbarProps.error(ERROR_STR.STD));
        }
      }
    }));
    buttons.push(dialogButtonsProps.init("Close", () => {
      setDialog(dialogProps.close());
    }));

    setDialog(dialogProps.info("Delete", `Do you want to delete ${name} ?`, buttons));
  };

  const updateTournament = (data) => {
    setSelectedTournament(data);
  };

  useEffect(() => {

    fetchData();

  }, []);


  return (
    <>
      {
        (loading) ?
          <Box sx={{ width: '100%', height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
          </Box>
          :
          (data.length) ?
            <Card sx={{ overflowX: "auto" }}>
              <Box sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Name
                      </TableCell>
                      <TableCell>
                        Venue
                      </TableCell>
                      <TableCell>
                        Category
                      </TableCell>
                      <TableCell>
                        Status
                      </TableCell>
                      <TableCell>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.map((data, i) => {

                      return (
                        <TableRow
                          hover
                          key={i}
                        >
                          <TableCell>
                            {data.name}
                          </TableCell>
                          <TableCell>
                            {data.venue}
                          </TableCell>
                          <TableCell>
                            {(data.category === TOURNAMENT_CATEGORY.NATIONAL) ? "National" : (data.category === TOURNAMENT_CATEGORY.INTERNATIONAL) ? "International" : ""}
                          </TableCell>
                          <TableCell>
                            {data.status}
                          </TableCell>
                          <TableCell>
                            {
                              (data.category === TOURNAMENT_CATEGORY.NATIONAL) ?
                                <Button
                                  onClick={() => { updateTournament(data) }}
                                >
                                  Edit
                                </Button>
                                :
                                <Button
                                  onClick={() => { updateTournament(data) }}
                                >
                                  View
                                </Button>
                            }

                            <Button onClick={() => onDelete(data)}>Delete</Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
              <TablePagination
                component="div"
                count={data.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </Card>
            :
            <Typography>No Tournaments</Typography>
      }
      <Info {...info} />
      <Snackbar {...snackbar} />
      <Backdrop open={backdrop} />
      <Dialog {...dialog} />
    </>
  )
}

export default TournamentView