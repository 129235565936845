import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ERROR_STR, dialogProps, infoProps, snackbarProps, REG_FORM_KEY, REG_FORM_LABEL, REG_VIEW_STYLE, dialogButtonsProps, REFREEE_LEVEL_STATE, REFREEE_LEVEL_NATIONAL, REFREEE_LEVEL_INTERNATIONAL, TOURNAMENT_VIEW_STYLE } from '../../constant';
import { API_STFI } from '../../api/stfi';
import { Avatar, Box, Button, Card, CardContent, CardHeader, CircularProgress, Unstable_Grid2 as Grid, Stack, Typography } from '@mui/material';
import Info from '../../components/Info';
import Backdrop from '../../components/Backdrop';
import FilePreviewBtn from '../../components/FilePreviewBtn';
import RejectedReasonDialog from './RejectedReasonDialog';
import Snackbar from '../../components/Snackbar';
import Dialog from '../../components/Dialog';

function returnZero(val) {
    if(!val) return 0;
    else return val;
}

const RegistrationPreview = () => {

    const [searchParams] = useSearchParams();
    const urlParam = searchParams.get("url");
    const status = searchParams.get("status");

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [backdrop, setBackdrop] = useState(false);
  
    const [dialog, setDialog] = useState(dialogProps.init());
  
    const [snackbar, setSnackbar] = useState(snackbarProps.init());
    snackbar.hide = () => {
      setSnackbar(snackbarProps.hide());
    };
  
    const [info, setInfo] = useState(infoProps.init());
  
    const [data, setData] = useState({});

    const getStatus = (status) => {
        return (status === "P")?"Pending":(status === "A")?"Approved":(status === "R")?"Rejected":"";
    };

    const fetchData = useCallback(async () => {
      try {

        let url = new URL(atob(urlParam));
        // Authenticate the google token
        const response = await API_STFI.REGISTRATION_PREVIEW(url.pathname + url.search);
  
        setData(response.data);
  
        // Hide loader
        setLoading(false);
  
        setInfo(infoProps.close());
  
      } catch (error) {

        setData({});
  
        // Hide loader
        setLoading(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Server responded with an error:', error.response.status, error.response);
          setInfo(infoProps.error(error.response.statusText));
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received from the server');
          setInfo(infoProps.error(ERROR_STR.NO_RESPONSE));
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error setting up the request:', error.message);
          setInfo(infoProps.error(ERROR_STR.STD));
        }
      }
    }, [urlParam]);

    const approveReject = async (approved, rejectedFields) => {
        try {

            setBackdrop(true);

            let url = new URL(atob(urlParam));
            const email = new URLSearchParams(url.search).get('email');

            var obj = {emailId:encodeURIComponent(email), approved:approved};
            if(rejectedFields) obj.rejectedFields = rejectedFields;
      
          // Authenticate the google token
          const response = await API_STFI.APPROVE_REJECT_REGISTRATION(obj);
          console.log(response);
    
          // Hide loader
          setBackdrop(false);

          var buttons = [];
            buttons.push(dialogButtonsProps.init("OK", () => {
                setDialog(dialogProps.close());
                navigate(-2);
            }));

            setDialog(dialogProps.info("Success", "Action submitted successfully", buttons));
    
        } catch (error) {
    
          // Hide loader
         
          if (error.response) {
            setBackdrop(false);
            console.error('Server responded with an error:', error.response.status, error.response);
            setSnackbar(snackbarProps.error(error.response.statusText));
          } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from the server');
            setBackdrop(false);
            setSnackbar(snackbarProps.error(ERROR_STR.NO_RESPONSE));
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error setting up the request:', error.message);
            setBackdrop(false);
            setSnackbar(snackbarProps.error(ERROR_STR.STD));
          }
        }
      };
  
    useEffect(() => {
  
      fetchData();
      
    }, [fetchData]);

    const Photo = () => {
        return(
            <Card  sx = {{height:"100%", p:3}} >
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        height:"100%",
                        width:"100%",
                        justifyContent:"center"
                    }}
                >
                    <Avatar
                        src={data.passportSizePhoto}
                        sx={{
                        height: 200,
                        width: 200,
                        background:"#e2e2e2"
                    }}
                    />
                    {/* <Typography sx={{...REG_VIEW_STYLE.value, textAlign:"center", pt:2}} >
                        {data["name"]}
                    </Typography> */}
                    {
                        (data[REG_FORM_KEY.PASSPORT_SIZE_PHOTO] !== undefined)?
                        <Grid
                            xs={12}
                            md={12}
                            textAlign={"center"}
                        >
                            <FilePreviewBtn url = {data[REG_FORM_KEY.PASSPORT_SIZE_PHOTO]} buttonLabel = {REG_FORM_LABEL.PASSPORT_SIZE_PHOTO} />
                        </Grid>
                        :
                        <></>
                    }
                </Box>
                
            </Card>
        );
    };

    const BasicDetails = () => {
        return(
            <Card sx = {{height:"100%"}}>
                <CardHeader
                subheader = {""}
                title = {"Basic Details"}
                />
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                        <Grid
                        container
                        spacing={3}
                        >
                        {
                            (data[REG_FORM_KEY.EMAIL] !== undefined)?
                            <Grid
                            xs={12}
                            sx={TOURNAMENT_VIEW_STYLE.wrapper}
                            >
                            <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                {REG_FORM_LABEL.EMAIL}
                            </Typography>
                            <Typography sx={TOURNAMENT_VIEW_STYLE.value} >
                                {data[REG_FORM_KEY.EMAIL]}
                            </Typography>
                            </Grid>
                            :
                            <></>
                        }
                        {
                            (data[REG_FORM_KEY.AADHAAR_CARD_NUMBER] !== undefined)?
                            <Grid
                            xs={12}
                            sx={REG_VIEW_STYLE.wrapper}
                            >
                            <Typography sx={REG_VIEW_STYLE.label} >
                                {REG_FORM_LABEL.AADHAAR_CARD_NUMBER}
                            </Typography>
                            <Typography sx={REG_VIEW_STYLE.value} >
                                {data[REG_FORM_KEY.AADHAAR_CARD_NUMBER]}
                            </Typography>
                            </Grid>
                            :
                            <></>
                        }
                        {
                            (data[REG_FORM_KEY.UPDATED_BY] !== undefined)?
                            <Grid
                            xs={12}
                            sx={REG_VIEW_STYLE.wrapper}
                            >
                            <Typography sx={REG_VIEW_STYLE.label} >
                                {REG_FORM_LABEL.UPDATED_BY}
                            </Typography>
                            <Typography sx={REG_VIEW_STYLE.value} >
                                {data[REG_FORM_KEY.UPDATED_BY]}
                            </Typography>
                            </Grid>
                            :
                            <></>
                        }
                        {
                            (data[REG_FORM_KEY.UPDATED_DATE] !== undefined)?
                            <Grid
                            xs={12}
                            sx={REG_VIEW_STYLE.wrapper}
                            >
                            <Typography sx={REG_VIEW_STYLE.label} >
                                {REG_FORM_LABEL.UPDATED_DATE}
                            </Typography>
                            <Typography sx={REG_VIEW_STYLE.value} >
                                {data[REG_FORM_KEY.UPDATED_DATE]}
                            </Typography>
                            </Grid>
                            :
                            <></>
                        }
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        );
    };

    const Role = () => {    

        if(!data.playerCurrentStatus && !data.officialCurrentStatus && !data.refereeCurrentStatus) return <></>;

        

        return(
        <Card sx = {{height:"100%"}} >
            <CardHeader
                subheader=""
                title="Role"
            />
            <CardContent sx={{ pt: 0 }}>
                <Box sx={{ m: -1.5 }}>
                <Stack spacing={1}>
                    <ul style={{marginTop:10, display:"flex", flexDirection:"column", gap:"10px", fontSize:"1.1em"}}>
                    {(data.playerCurrentStatus)?<li>{`Player (${getStatus(data.playerCurrentStatus)})`}</li>:<></>}
                    {(data.officialCurrentStatus)?<li>{`Official (${getStatus(data.officialCurrentStatus)})`}</li>:<></>}
                    {(data.refereeCurrentStatus)?<li>{`Referee (${getStatus(data.refereeCurrentStatus)})`}</li>:<></>}
                    </ul>
                </Stack>
                </Box>
            </CardContent>
        </Card>
        );
    };

    const Personal = () => {
        return(
            <Card>
                <CardHeader
                    subheader=""
                    title="Personal Details"
                />
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                        <Grid
                        container
                        spacing={3}
                        >
                            {
                            (data[REG_FORM_KEY.NAME] !== undefined)?
                            <Grid
                                xs={12}
                                md={4}
                                sx={REG_VIEW_STYLE.wrapper}
                            >
                            <Typography sx={REG_VIEW_STYLE.label}>
                                    {REG_FORM_LABEL.NAME}
                                </Typography>
                                <Typography sx={REG_VIEW_STYLE.value} >
                                    {data[REG_FORM_KEY.NAME]}
                                </Typography>
                            </Grid>
                            :
                            <></>
                            }
                            {
                            (data[REG_FORM_KEY.FATHER_NAME] !== undefined)?
                            <Grid
                                xs={12}
                                md={4}
                                sx={REG_VIEW_STYLE.wrapper}
                            >
                            <Typography sx={REG_VIEW_STYLE.label}>
                                    {REG_FORM_LABEL.FATHER_NAME}
                                </Typography>
                                <Typography sx={REG_VIEW_STYLE.value} >
                                    {data[REG_FORM_KEY.FATHER_NAME]}
                                </Typography>
                            </Grid>
                            :
                            <></>
                            }
                            {
                            (data[REG_FORM_KEY.MOTHER_NAME] !== undefined)?
                            <Grid
                                xs={12}
                                md={4}
                                sx={REG_VIEW_STYLE.wrapper}
                            >
                            <Typography sx={REG_VIEW_STYLE.label}>
                                    {REG_FORM_LABEL.MOTHER_NAME}
                                </Typography>
                                <Typography sx={REG_VIEW_STYLE.value} >
                                    {data[REG_FORM_KEY.MOTHER_NAME]}
                                </Typography>
                            </Grid>
                            :
                            <></>
                            }
                            {
                            (data[REG_FORM_KEY.PHONE_NUMBER] !== undefined)?
                            <Grid
                                xs={12}
                                md={4}
                                sx={REG_VIEW_STYLE.wrapper}
                            >
                            <Typography sx={REG_VIEW_STYLE.label}>
                                    {REG_FORM_LABEL.PHONE_NUMBER}
                                </Typography>
                                <Typography sx={REG_VIEW_STYLE.value} >
                                    {data[REG_FORM_KEY.PHONE_NUMBER]}
                                </Typography>
                            </Grid>
                            :
                            <></>
                            }
                            {
                            (data[REG_FORM_KEY.DATE_OF_BIRTH] !== undefined)?
                            <Grid
                                xs={12}
                                md={4}
                                sx={REG_VIEW_STYLE.wrapper}
                            >
                            <Typography sx={REG_VIEW_STYLE.label}>
                                    {REG_FORM_LABEL.DATE_OF_BIRTH}
                                </Typography>
                                <Typography sx={REG_VIEW_STYLE.value} >
                                    {data[REG_FORM_KEY.DATE_OF_BIRTH]}
                                </Typography>
                            </Grid>
                            :
                            <></>
                            }
                            {
                            (data[REG_FORM_KEY.PLACE_OF_BIRTH] !== undefined)?
                            <Grid
                                xs={12}
                                md={4}
                                sx={REG_VIEW_STYLE.wrapper}
                            >
                            <Typography sx={REG_VIEW_STYLE.label}>
                                    {REG_FORM_LABEL.PLACE_OF_BIRTH}
                                </Typography>
                                <Typography sx={REG_VIEW_STYLE.value} >
                                    {data[REG_FORM_KEY.PLACE_OF_BIRTH]}
                                </Typography>
                            </Grid>
                            :
                            <></>
                            }
                            {
                            (data[REG_FORM_KEY.GENDER] !== undefined)?
                            <Grid
                                xs={12}
                                md={4}
                                sx={REG_VIEW_STYLE.wrapper}
                            >
                            <Typography sx={REG_VIEW_STYLE.label}>
                                    {REG_FORM_LABEL.GENDER}
                                </Typography>
                                <Typography sx={REG_VIEW_STYLE.value} >
                                    {(data[REG_FORM_KEY.GENDER] === "M")?"Male":(data[REG_FORM_KEY.GENDER] === "F")?"Female":data[REG_FORM_KEY.GENDER]}
                                </Typography>
                            </Grid>
                            :
                            <></>
                            }
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        );
    };

    const Player = () => {

        if(!data.playerCurrentStatus) return(<></>);

        return(
            <Card>
                <CardHeader
                    subheader=""
                    title="Player Details"
                />
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                        <Grid
                        container
                        spacing={3}
                        >
                            {
                                (true)?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.NATIONAL_PLAYED}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {returnZero(data[REG_FORM_KEY.NATIONAL_PLAYED])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (true)?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.INTERNATIONAL_PLAYED}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {returnZero(data[REG_FORM_KEY.INTERNATIONAL_PLAYED])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.PLAYER_REG_ID])?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.REG_ID}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {(data[REG_FORM_KEY.PLAYER_REG_ID])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.PLAYER_UPDATED_BY])?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.UPDATED_BY}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {(data[REG_FORM_KEY.PLAYER_UPDATED_BY])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.PLAYER_UPDATED_DATE])?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.UPDATED_DATE}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {(data[REG_FORM_KEY.PLAYER_UPDATED_DATE])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.PLAYER_REGISTERED_BY])?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.REGISTERED_BY}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {(data[REG_FORM_KEY.PLAYER_REGISTERED_BY])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.PLAYER_REGISTERED_DATE])?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.REGISTERED_DATE}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {(data[REG_FORM_KEY.PLAYER_REGISTERED_DATE])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.PLAYER_ENROLL_STATE_NAME])?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.ENROLLED_STATE_NAME}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {(data[REG_FORM_KEY.PLAYER_ENROLL_STATE_NAME])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.PLAYER_CURRENT_STATUS])?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.STATUS}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {getStatus(data[REG_FORM_KEY.PLAYER_CURRENT_STATUS])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        );
    };

    const Official = () => {

        if(!data.officialCurrentStatus) return(<></>);

        return(
            <Card>
                <CardHeader
                    subheader=""
                    title="Official Details"
                />
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                        <Grid
                        container
                        spacing={3}
                        >
                            {
                                (true)?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.NATIONAL_PARTICIPATED_AS_COACH}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                    {returnZero(data[REG_FORM_KEY.NATIONAL_PARTICIPATED_AS_COACH])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (true)?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.INTERNATIONAL_PARTICIPATED_AS_COACH}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                    {returnZero(data[REG_FORM_KEY.INTERNATIONAL_PARTICIPATED_AS_COACH])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (true)?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.NATIONAL_PARTICIPATED_AS_MANAGER}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                    {returnZero(data[REG_FORM_KEY.NATIONAL_PARTICIPATED_AS_MANAGER])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (true)?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.INTERNATIONAL_PARTICIPATED_AS_MANAGER}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                    {returnZero(data[REG_FORM_KEY.INTERNATIONAL_PARTICIPATED_AS_MANAGER])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.OFFICIAL_REG_ID])?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.REG_ID}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {(data[REG_FORM_KEY.OFFICIAL_REG_ID])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.OFFICIAL_UPDATED_BY])?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.UPDATED_BY}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {(data[REG_FORM_KEY.OFFICIAL_UPDATED_BY])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.OFFICIAL_UPDATED_DATE])?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.UPDATED_DATE}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {(data[REG_FORM_KEY.OFFICIAL_UPDATED_DATE])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.OFFICIAL_REGISTERED_BY])?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.REGISTERED_BY}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {(data[REG_FORM_KEY.OFFICIAL_REGISTERED_BY])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.OFFICIAL_REGISTERED_DATE])?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.REGISTERED_DATE}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {(data[REG_FORM_KEY.OFFICIAL_REGISTERED_DATE])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.OFFICIAL_ENROLL_STATE_NAME])?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.ENROLLED_STATE_NAME}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {(data[REG_FORM_KEY.OFFICIAL_ENROLL_STATE_NAME])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.OFFICIAL_CURRENT_STATUS])?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.STATUS}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {getStatus(data[REG_FORM_KEY.OFFICIAL_CURRENT_STATUS])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        );
    };

    const Referee = () => {

        if(!data.refereeCurrentStatus) return(<></>);

        let refereeLevel = "";

        if(data[REG_FORM_KEY.REFREEE_LEVEL]) {

            if(data[REG_FORM_KEY.REFREEE_LEVEL] === REFREEE_LEVEL_STATE) {
                refereeLevel = "State";
            }
            else if(data[REG_FORM_KEY.REFREEE_LEVEL] === REFREEE_LEVEL_NATIONAL) {
                refereeLevel = "National";
            }
            else if(data[REG_FORM_KEY.REFREEE_LEVEL] === REFREEE_LEVEL_INTERNATIONAL) {
                refereeLevel = "International";
            }
        }

        return(
            <Card>
                <CardHeader
                    subheader=""
                    title="Referee Details"
                />
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                        <Grid
                        container
                        spacing={3}
                        >
                            {
                                (true)?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.NATIONAL_REFEREED}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                    {returnZero(data[REG_FORM_KEY.NATIONAL_REFEREED])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (true)?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.INTERNATIONAL_REFEREED}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                    {returnZero(data[REG_FORM_KEY.INTERNATIONAL_REFEREED])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.REFREEE_LEVEL] !== undefined)?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.REFREEE_LEVEL}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {refereeLevel}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.REFREEE_PROOF] !== undefined)?
                                <Grid
                                    xs={12}
                                    md={4}
                                >
                                    <FilePreviewBtn url = {data[REG_FORM_KEY.REFREEE_PROOF]} buttonLabel = {REG_FORM_LABEL.REFREEE_PROOF} />
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.REFEREE_REG_ID])?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.REG_ID}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {(data[REG_FORM_KEY.REFEREE_REG_ID])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.REFEREE_UPDATED_BY])?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.UPDATED_BY}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {(data[REG_FORM_KEY.REFEREE_UPDATED_BY])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.REFEREE_UPDATED_DATE])?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.UPDATED_DATE}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {(data[REG_FORM_KEY.REFEREE_UPDATED_DATE])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.REFEREE_REGISTERED_BY])?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.REGISTERED_BY}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {(data[REG_FORM_KEY.REFEREE_REGISTERED_BY])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.REFEREE_REGISTERED_DATE])?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.REGISTERED_DATE}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {(data[REG_FORM_KEY.REFEREE_REGISTERED_DATE])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.REFEREE_ENROLL_STATE_NAME])?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.ENROLLED_STATE_NAME}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {(data[REG_FORM_KEY.REFEREE_ENROLL_STATE_NAME])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.REFEREE_CURRENT_STATUS])?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.STATUS}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                        {getStatus(data[REG_FORM_KEY.REFEREE_CURRENT_STATUS])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        );
    };
    
    const Passport = () => {

        var passportUpdate = (!data.playerCurrentStatus && !data.officialCurrentStatus && !data.refereeCurrentStatus);

        if(!data[REG_FORM_KEY.PASSPORT_NUMBER] && !data[REG_FORM_KEY.PASSPORT_EXPIRY_MONTH_AND_YEAR] && !data[REG_FORM_KEY.PASSPORT_ISSUE_PLACE] && !data[REG_FORM_KEY.PASSPORT_COPY]) return(<></>);

        return(
            <Card>
                <CardHeader
                    subheader={""}
                    title={`Passport Details ${(passportUpdate)?"(Pending)":""}`}
                />
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                        <Grid
                        container
                        spacing={3}
                        >
                            {
                                (data[REG_FORM_KEY.PASSPORT_NUMBER] !== undefined)?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.PASSPORT_NUMBER}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                    {(data[REG_FORM_KEY.PASSPORT_NUMBER])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.PASSPORT_EXPIRY_MONTH_AND_YEAR] !== undefined)?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.PASSPORT_EXPIRY_MONTH_AND_YEAR}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                    {(data[REG_FORM_KEY.PASSPORT_EXPIRY_MONTH_AND_YEAR])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.PASSPORT_ISSUE_PLACE] !== undefined)?
                                <Grid
                                    xs={12}
                                    md={4}
                                    sx={REG_VIEW_STYLE.wrapper}
                                >
                                    <Typography sx={REG_VIEW_STYLE.label}>
                                        {REG_FORM_LABEL.PASSPORT_ISSUE_PLACE}
                                    </Typography>
                                    <Typography sx={REG_VIEW_STYLE.value} >
                                    {(data[REG_FORM_KEY.PASSPORT_ISSUE_PLACE])}
                                    </Typography>
                                </Grid>
                                :
                                <></>
                            }
                            {
                                (data[REG_FORM_KEY.PASSPORT_COPY] !== undefined)?
                                <Grid
                                    xs={12}
                                    md={4}
                                >
                                    <FilePreviewBtn buttonLabel={REG_FORM_LABEL.PASSPORT_COPY} url={data[REG_FORM_KEY.PASSPORT_COPY]}/>
                                </Grid>
                                :
                                <></>
                            }
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        );
    };

    const Documents = () => {
        return(
            <Card>
                <CardHeader
                    subheader=""
                    title="Documents"
                />
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                        <Grid
                        container
                        spacing={3}
                        >
                            {
                                (data[REG_FORM_KEY.GOVERNMENT_PROOF] !== undefined)?
                                <Grid
                                    xs={12}
                                    md={6}
                                >
                                    <FilePreviewBtn buttonLabel={REG_FORM_LABEL.GOVERNMENT_PROOF} url={data[REG_FORM_KEY.GOVERNMENT_PROOF]}/>
                                </Grid>
                                :
                                <></>
                            }
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        );
    };

    function ApproveReject() {

        const [rejectedReasonDialog, setRejectedReasonDialog] = React.useState(false);
      
          const approve = () => {
            approveReject(true);
          };
      
          const reject = () => {
            setRejectedReasonDialog(true);
          };
      
          const onSubmit = (rejectedFields) => {
      
            // if(rejectedFields && rejectedFields.length) {
            //     let str = "Document: ";
            //     rejectedFields.forEach(value => {
            //         str += value+",";
            //     });
            //     rejectedReason = str.substring(0, str.length - 1);
            // }

            console.log(rejectedFields);

            approveReject(false, rejectedFields);
      
          };

          var options = [];

          if(data.status !== "A") {
            options = [
                { id: REG_FORM_KEY.PASSPORT_SIZE_PHOTO, label: REG_FORM_LABEL.PASSPORT_SIZE_PHOTO },
                { id: REG_FORM_KEY.GOVERNMENT_PROOF, label: REG_FORM_LABEL.GOVERNMENT_PROOF },
            ];
            }
      
          if(data[REG_FORM_KEY.PASSPORT_COPY]) options.push({ id: REG_FORM_KEY.PASSPORT_COPY, label: "Passport" });

          if(data.playerCurrentStatus === "P") options.push({ id: "player", label: "Player" });
          if(data.officialCurrentStatus === "P") options.push({ id: "official", label: "Official" });
          if(data.refereeCurrentStatus === "P") options.push({ id: "referee", label: "Referee" });
      
        return (
          <>
              {
                (status === "P")?
                <Stack mt = {6} justifyContent="center" alignItems="center" spacing={2} direction="row">
                    <Button onClick = {async () => approve()} variant="contained">Approve</Button>
                    <Button onClick = {async () => reject()} variant="contained">Reject</Button>
                </Stack>
                :
               <></>
              }
              <RejectedReasonDialog open = {rejectedReasonDialog} onSubmit = {onSubmit} onClose = {() => {setRejectedReasonDialog(false)}} options = {options} />
          </>
        )
    }

    return (
      <>
        {
          (loading) ?
          <Box sx={{ width: '100%', height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
          </Box>
          :
          (Object.keys(data).length)?
          <Card sx = {{padding:"1em"}} >
            <Grid
              container
              spacing={3}
            >	
              <Grid
                xs={12}	
                md={4}
              >
                <Photo />
              </Grid>
              <Grid
                xs={12}
                md={4}
              >
                <BasicDetails />
              </Grid>
              <Grid
                xs={12}
                md={4}
              >
                <Role />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={12}
                lg={12}
              >
                <Personal/>
              </Grid>
            </Grid>
            {
              (true) ?
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    xs={12}
                    md={12}
                    lg={12}
                  >
                    <Player />
                  </Grid>
                </Grid>
                :
                <></>
            }
            {
              (true) ?
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    xs={12}
                    md={12}
                    lg={12}
                  >
                    <Official />
                  </Grid>
                </Grid>
                :
                <></>
            }
            {
              (true) ?
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    xs={12}
                    md={12}
                    lg={12}
                  >
                    <Referee />
                  </Grid>
                </Grid>
                :
                <></>
            }
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={12}
                lg={12}
              >
                <Passport  />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={12}
                lg={12}
              >
                <Documents />
              </Grid>
            </Grid>
            <ApproveReject />
          </Card>   
          :
          <></>
        }
        <Info {...info} />
        <Snackbar {...snackbar} />
        <Backdrop open = {backdrop}/>
        <Dialog {...dialog} /> 
      </>
    );
}

export default RegistrationPreview