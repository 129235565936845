import React, { useCallback, useMemo, useState } from 'react'
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

function applyPagination(documents, page, rowsPerPage) {
  return documents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

const useCustomers = (data, page, rowsPerPage) => {
  return useMemo(
    () => {
      return applyPagination(data, page, rowsPerPage);
    },
    [data, page, rowsPerPage]
  );
};

const DashboardTable = ({data}) => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const tableData = useCustomers(data, page, rowsPerPage);

  const handlePageChange = useCallback(
    (event, value) => {
      setPage(value);
    },
    []
  );

  const handleRowsPerPageChange = useCallback(
    (event) => {
      setRowsPerPage(event.target.value);
    },
    []
  );

  const check = (num) => {
    if(!num) return 0;
    else return Math.floor(num);
  };

  return (
    <>
      <Card sx={{overflowX:"auto" }}>
        <Box sx={{ minWidth: 800}}>
        <TableContainer sx={{ maxHeight: 400 }}>
            <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                <TableCell>
                    State Name
                </TableCell>
                <TableCell>
                    Registrations
                </TableCell>
                <TableCell>
                    Approved
                </TableCell>
                <TableCell>
                    Pending
                </TableCell>
                <TableCell>
                    Rejected
                </TableCell>
                <TableCell>
                    Male
                </TableCell>
                <TableCell>
                    Female
                </TableCell>
                <TableCell>
                    Player
                </TableCell>
                <TableCell>
                    Offical
                </TableCell>
                <TableCell>
                    Referee
                </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {tableData.map((value, i) => {
                return (
                    <TableRow
                      hover
                      key={i}
                    >
                    <TableCell>
                        {value.stateName}
                    </TableCell>
                    <TableCell>
                        {check(value.totalNumberOfRegistration)}
                    </TableCell>
                    <TableCell>
                        {check(value.totalApprovedCount)}
                    </TableCell>
                    <TableCell>
                        {check(value.totalPendingCount)}
                    </TableCell>
                    <TableCell>
                        {check(value.totalRejectedCount)}
                    </TableCell>
                    <TableCell>
                        {check(value.totalMaleCount)}
                    </TableCell>
                    <TableCell>
                        {check(value.totalFemaleCount)}
                    </TableCell>
                    <TableCell>
                        {check(value.playerRegistrationCount)}
                    </TableCell>
                    <TableCell>
                        {check(value.officialRegistrationCount)}
                    </TableCell>
                    <TableCell>
                        {check(value.refereeRegistrationCount)}
                    </TableCell>
                    </TableRow>
                );
                })}
            </TableBody>
            </Table>
            </TableContainer>
        </Box>
      <TablePagination
        component="div"
        count={data.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 100]}
      />
    </Card>
  </>
)
}

export default DashboardTable