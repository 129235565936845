import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { dialogButtonsProps, dialogProps, ERROR_STR, FILE_MAX_SIZE, infoProps, snackbarProps } from '../../constant';
import Info from '../../components/Info';
import { Box, Button, CircularProgress } from '@mui/material';
import Backdrop from '../../components/Backdrop';
import { API_STFI } from '../../api/stfi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TournamentBasicFields from './TournamentBasicFields';
import TournamentEvents from './TournamentEvents';
import Snackbar from '../../components/Snackbar';
import Dialog from '../../components/Dialog';
import TournamentMembers from './TournamentMembers';
import CustomizedDialogs from '../../components/CustomizedDialogs';
import TournamentFormView from './TournamentFormView';

export const TOURNAMENT_FORM_KEY = {
    CATEGORY: "category", // "N" || "I" 
    NAME: "name", // max 256 char
    VENUE: "venue", // max 256 char
    START_DATE: "startDate", // <DD-MM-YYYY>
    END_DATE: "endDate", // <DD-MM-YYYY>
    REG_CLOSING_DATE: "registrationClosingDate", // <DD-MM-YYYY>
    REG_STARTING_DATE: "registrationStartingDate", // <DD-MM-YYYY>
    AGE_LIMIT: "ageLimit", // <MM-YYYY>
    OFFICIAL_DOC: "officialDocument", // Max 1MB -> only PDF
    EVENTS: "events",
    INTERNATIONAL: "international"
};



const reducer = (state, action) => {
    switch (action.type) {
        case "UPDATE": {
            state[action.name] = action.value;
            return { ...state };
        }
        case "SET_STATE": {

            let obj = { ...action.selectedTournament };

            for (let key in obj) {

                if (key === TOURNAMENT_FORM_KEY.EVENTS) {

                    obj[key].forEach((e) => {

                        for (var i = 0; i < state[key].length; i++) {
                            if (state[key][i].event === e.event) {

                                e.selected = true;
                                for (var key1 in e) {
                                    if (e[key1]) state[key][i][key1] = e[key1];
                                }
                                break;
                            }
                        }
                    });
                }
                else state[key] = obj[key];
            }

            return { ...state };
        }
        case "UPDATE_COUNT": {
            let events = state[TOURNAMENT_FORM_KEY.EVENTS];
            for (let i = 0; i < events.length; i++) {
                if (events[i].event === action.event) {
                    events[i][action.name] = action.value;
                    break;
                }
            }
            return { ...state };
        }
        case "CHECK_EVENT": {
            let events = state[TOURNAMENT_FORM_KEY.EVENTS];
            for (let i = 0; i < events.length; i++) {
                if (events[i].event === action.event) {
                    events[i].selected = action.checked;
                    break;
                }
            }
            return { ...state };
        }
        case "UPDATE_STATE": {

            let events = state[TOURNAMENT_FORM_KEY.EVENTS];
            for (let i = 0; i < events.length; i++) {
                if (events[i].event === action.event) {
                    const arr = events[i][action.gender];
                    const index = arr.indexOf(action.stateCode)
                    if (action.checked === true && index === -1) {
                        arr.push(action.stateCode);
                    } else {
                        arr.splice(index, 1);
                    }
                    break;
                }
            }
            return { ...state };
        }
        case "SELECT_ALL": {

            let events = state[TOURNAMENT_FORM_KEY.EVENTS];
            for (let i = 0; i < events.length; i++) {
                if (events[i].event === action.event) {

                    let arr = events[i][action.gender];
                    arr = [];
                    if (action.checked) {
                        action.states.forEach((v, i) => {
                            arr.push(v.stateCode);
                        });
                    }
                    events[i][action.gender] = arr;
                    break;
                }
            }
            return { ...state };
        }
        case "ADD_MEMBER": {
            let events = state[TOURNAMENT_FORM_KEY.INTERNATIONAL];
            for (let i = 0; i < events.length; i++) {
                if (events[i].role === action.role) {

                    let arr = [], arr2 = [];
                    if (action.data.gender === "M") {
                        arr = events[i].qualifiedMen;
                        arr2 = events[i].qualifiedMenDetails;
                    }
                    else {
                        arr = events[i].qualifiedWomen;
                        arr2 = events[i].qualifiedWomenDetails;
                    }

                    if (!arr.includes(action.registrationId)) {
                        arr.push(action.registrationId);
                        arr2.push(action.data);
                    }
                    break;
                }
            }
            return { ...state };
        }
        case "REMOVE_MEMBER": {
            let events = state[TOURNAMENT_FORM_KEY.INTERNATIONAL];
            for (let i = 0; i < events.length; i++) {
                if (events[i].role === action.role) {

                    let arr = [], arr2 = [];
                    if (action.data.gender === "M") {
                        arr = events[i].qualifiedMen;
                        arr2 = events[i].qualifiedMenDetails;
                    }
                    else {
                        arr = events[i].qualifiedWomen;
                        arr2 = events[i].qualifiedWomenDetails;
                    }

                    const index = arr.indexOf(action.registrationId);
                    if (index > -1) { // only splice array when item is found
                        arr.splice(index, 1); // 2nd parameter means remove one item only
                    }

                    for (let j = 0; j < arr2.length; j++) {
                        if ((arr2[j].playerRegistrationId === action.registrationId) || (arr2[j].officalRegistrationId === action.registrationId) || (arr2[j].refereeRegistrationId === action.registrationId)) {
                            arr2.splice(j, 1);
                            break;
                        }
                    }
                    break;
                }
            }
            return { ...state };
        }
        default:
            return state;
    }
};

function TournamentForm({ selectedTournament, setSelectedTournament }) {

    const initialTournament = {
        [TOURNAMENT_FORM_KEY.CATEGORY]: "",
        [TOURNAMENT_FORM_KEY.NAME]: "",
        [TOURNAMENT_FORM_KEY.VENUE]: "",
        [TOURNAMENT_FORM_KEY.START_DATE]: "",
        [TOURNAMENT_FORM_KEY.END_DATE]: "",
        [TOURNAMENT_FORM_KEY.REG_STARTING_DATE]: "",
        [TOURNAMENT_FORM_KEY.REG_CLOSING_DATE]: "",
        [TOURNAMENT_FORM_KEY.AGE_LIMIT]: "",
        [TOURNAMENT_FORM_KEY.OFFICIAL_DOC]: "",
        [TOURNAMENT_FORM_KEY.EVENTS]: [
            {
                "event": "TE",
                "selected": false,
                "label": "Team",
                "maximumNumberOfPlayers": 15,
                "maximumNumberOfCoaches": 2,
                "maximumNumberOfManagers": 1,
                "qualifiedMaleStateCodes": [],
                "qualifiedFemaleStateCodes": [],
            },
            {
                "event": "RE",
                "selected": false,
                "label": "Regu",
                "maximumNumberOfPlayers": 5,
                "maximumNumberOfCoaches": 1,
                "maximumNumberOfManagers": 1,
                "qualifiedMaleStateCodes": [],
                "qualifiedFemaleStateCodes": [],
            },
            {
                "event": "QE",
                "selected": false,
                "label": "Quad",
                "maximumNumberOfPlayers": 6,
                "maximumNumberOfCoaches": 1,
                "maximumNumberOfManagers": 1,
                "qualifiedMaleStateCodes": [],
                "qualifiedFemaleStateCodes": [],
            },
            {
                "event": "DE",
                "selected": false,
                "label": "Doubles",
                "maximumNumberOfPlayers": 3,
                "maximumNumberOfCoaches": 1,
                "maximumNumberOfManagers": 1,
                "qualifiedMaleStateCodes": [],
                "qualifiedFemaleStateCodes": [],
            },
        ],
        [TOURNAMENT_FORM_KEY.INTERNATIONAL]: [
            {
                "role": "P",
                "qualifiedMen": [],
                "qualifiedWomen": [],
                "qualifiedMenDetails": [],
                "qualifiedWomenDetails": [],
            },
            {
                "role": "C",
                "qualifiedMen": [],
                "qualifiedWomen": [],
                "qualifiedMenDetails": [],
                "qualifiedWomenDetails": [],
            }, {
                "role": "M",
                "qualifiedMen": [],
                "qualifiedWomen": [],
                "qualifiedMenDetails": [],
                "qualifiedWomenDetails": [],
            }, {
                "role": "R",
                "qualifiedMen": [],
                "qualifiedWomen": [],
                "qualifiedMenDetails": [],
                "qualifiedWomenDetails": [],
            }
        ],
    };

    const [searchParams] = useSearchParams();
    const eventType = searchParams.get("type");

    const [loading] = useState(false);
    const [info] = useState(infoProps.init());
    const [backdrop, setBackdrop] = useState(false);
    const [snackbar, setSnackbar] = useState(snackbarProps.init());
    snackbar.hide = () => {
        setSnackbar(snackbarProps.hide());
    };
    const [dialog, setDialog] = useState(dialogProps.init());
    const navigate = useNavigate();
    const [tournament, dispatch] = useReducer(reducer, initialTournament);

    const [preview, setPreview] = useState(false);
    const [formDetails, setFormDetails] = useState(null);

    const createTournament = useCallback(async (formData) => {
        try {

            snackbar.hide();
            setBackdrop(true);
            // Authenticate the google token

            const response = await API_STFI.CREATE_TOURNAMENT(formData);

            if (response.status === 200) {
                var buttons = [];
                buttons.push(dialogButtonsProps.init("OK", () => {
                    setDialog(dialogProps.close());
                    navigate(-1);
                }));

                const name = formData.get(TOURNAMENT_FORM_KEY.NAME);
                const header = (formData.get(TOURNAMENT_FORM_KEY.CATEGORY) === "N") ? "National" : "International";

                setDialog(dialogProps.info(header + " Tournament", name + " created successfully", buttons));
                setBackdrop(false);
            }
            else {
                setSnackbar(snackbarProps.error(ERROR_STR.STD));
                setBackdrop(false);
            }

        } catch (error) {

            // Hide loader
            setBackdrop(false);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Server responded with an error:', error.response.status, error.response);

                if (error.response.data && error.response.data.message) {
                    setSnackbar(snackbarProps.error(error.response.data.message));
                    setBackdrop(false);
                }
                else setSnackbar(snackbarProps.error(error.response.statusText));
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received from the server');
                setSnackbar(snackbarProps.error(ERROR_STR.NO_RESPONSE));
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error setting up the request:', error.message);
                setSnackbar(snackbarProps.error(ERROR_STR.STD));
            }
        }
    }, [navigate, snackbar]);

    const updateTournament = useCallback(async (formData) => {
        try {

            snackbar.hide();
            setBackdrop(true);
            // Authenticate the google token

            const response = await API_STFI.UPDATE_TOURNAMENT(formData.get("id"), formData);

            if (response.status === 200) {
                var buttons = [];
                buttons.push(dialogButtonsProps.init("OK", () => {
                    setDialog(dialogProps.close());
                    setSelectedTournament(null);
                }));

                const name = formData.get(TOURNAMENT_FORM_KEY.NAME);
                const header = (formData.get(TOURNAMENT_FORM_KEY.CATEGORY) === "N") ? "National" : "International";

                setDialog(dialogProps.info(header + " Tournament", name + " updated successfully", buttons));
                setBackdrop(false);
            }
            else {
                setSnackbar(snackbarProps.error(ERROR_STR.STD));
                setBackdrop(false);
            }

        } catch (error) {

            // Hide loader
            setBackdrop(false);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Server responded with an error:', error.response.status, error.response);

                if (error.response.data && error.response.data.message) {
                    setSnackbar(snackbarProps.error(error.response.data.message));
                    setBackdrop(false);
                }
                else setSnackbar(snackbarProps.error(error.response.statusText));
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received from the server');
                setSnackbar(snackbarProps.error(ERROR_STR.NO_RESPONSE));
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error setting up the request:', error.message);
                setSnackbar(snackbarProps.error(ERROR_STR.STD));
            }
        }
    }, [setSelectedTournament, snackbar]);

    const handleSubmit = useCallback(
        async (event) => {

            event.preventDefault();

            const formData = new FormData();

            if (tournament[TOURNAMENT_FORM_KEY.CATEGORY] === "N") {

                var isEventSelected = false;

                tournament[TOURNAMENT_FORM_KEY.EVENTS].forEach((v) => {
                    if (v.selected) isEventSelected = true;
                });

                if (!isEventSelected) {
                    setSnackbar(snackbarProps.error(`Select atleast one Event`));
                    return;
                }

                var isStateSelected = false;

                tournament[TOURNAMENT_FORM_KEY.EVENTS].forEach((v) => {
                    if (v["qualifiedMaleStateCodes"].length > 0) isStateSelected = true;
                    if (v["qualifiedFemaleStateCodes"].length > 0) isStateSelected = true;
                });

                if (!isStateSelected) {
                    setSnackbar(snackbarProps.error(`Select atleast one States`));
                    return;
                }

            }

            for (let key in tournament) {
                if (tournament[TOURNAMENT_FORM_KEY.CATEGORY] === "N" && key === TOURNAMENT_FORM_KEY.EVENTS) {

                    let i = 0;
                    tournament[TOURNAMENT_FORM_KEY.EVENTS].map((item) => {
                        if (item.selected) {
                            for (let key2 in item) {
                                if (typeof item[key2] === "object") {
                                    item[key2].map((v, j) => { formData.set(`events[${i}].${key2}[${j}]`, v); return true;});
                                }
                                else formData.set(`events[${i}].${key2}`, item[key2]);
                            };
                            i++;
                        }
                        return true;
                    });
                }
                else if (tournament[TOURNAMENT_FORM_KEY.CATEGORY] === "I" && key === TOURNAMENT_FORM_KEY.INTERNATIONAL) {

                    tournament[TOURNAMENT_FORM_KEY.INTERNATIONAL].map((item, index) => {
                        for (let key in item) {

                            if (key === "qualifiedMenDetails" || key === "qualifiedWomenDetails") continue;

                            if (typeof item[key] === "object") {
                                item[key].map((v, i) => { formData.set(`international[${index}].${key}[${i}]`, v); return true});
                            }
                            else formData.set(`international[${index}].${key}`, item[key])
                        };
                        return true;
                    });
                }
                else if (tournament[key]) formData.set(key, tournament[key]);
            }

            if (tournament[TOURNAMENT_FORM_KEY.CATEGORY] === "N") formData.delete(TOURNAMENT_FORM_KEY.INTERNATIONAL);
            else formData.delete(TOURNAMENT_FORM_KEY.EVENTS);

            formData.delete(TOURNAMENT_FORM_KEY.OFFICIAL_DOC);

            const formElements = event.target.elements;

            for (let i = 0; i < formElements.length; i++) {
                const element = formElements[i];

                if (element.type === 'file' && element.files.length) {
                    if (element.files[0].size >= FILE_MAX_SIZE) {
                        setSnackbar(snackbarProps.error(`'${element.files[0].name}' file size is more than 1 MB`));
                        return;
                    }
                    formData.set(element.name, element.files[0]);
                    break;
                }
            }

            setFormDetails(formData);
            setPreview(true);

            ;
        }
        , [tournament, createTournament]
    );

    const handleChange = useCallback(
        (event) => {

            const { name, value } = event.target;

            dispatch({ type: "UPDATE", name: name, value: value });
        },
        []
    );

    const handleDateChange = useCallback(
        (name, value) => {

            dispatch({ type: "UPDATE", name: name, value: value });
        },
        []
    );


    const resetAgeLimit = () => {
        dispatch({ type: "UPDATE", name: TOURNAMENT_FORM_KEY.AGE_LIMIT, value: "" });
    };

    const handleCreateTournament = () => {
        setPreview(false);
        if (selectedTournament) updateTournament(formDetails);
        else createTournament(formDetails);
    };

    useEffect(() => {
        if (selectedTournament) dispatch({ type: "SET_STATE", selectedTournament: selectedTournament });
        else {
            dispatch({ type: "UPDATE", name: TOURNAMENT_FORM_KEY.CATEGORY, value: eventType });
        }
    }, [selectedTournament, eventType])

    return (
        <>
            {
                (loading) ?
                    <Box sx={{ width: '100%', height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress />
                    </Box>
                    :
                    (info.open) ?
                        <Info {...info} />
                        :
                        <form
                            onSubmit={handleSubmit}
                        >
                            <TournamentBasicFields tournament={tournament} handleChange={handleChange} selectedTournament={selectedTournament} handleDateChange={handleDateChange} resetAgeLimit={resetAgeLimit} />
                            {
                                (tournament[TOURNAMENT_FORM_KEY.CATEGORY] === "N") ?
                                    <TournamentEvents tournament={tournament} dispatch={dispatch} />
                                    :
                                    <TournamentMembers tournament={tournament} dispatch={dispatch} />
                            }
                            <Box sx={{ mt: 5, textAlign: "center" }}>
                                <Button type='submit' variant='contained'>Proceed</Button>
                            </Box>
                            <Snackbar {...snackbar} />
                            <Backdrop open={backdrop} />
                            <Dialog {...dialog} />
                            <CustomizedDialogs open={preview} setOpen={setPreview} header={"Preview and Submit"} button={"Submit"} handleSubmit={handleCreateTournament} content={<TournamentFormView data={tournament} />} />
                        </form>
            }
        </>
    )
}

export default TournamentForm