import React, { useState } from 'react'
import { Layout } from '../layouts/dashboard/layout'
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { getUserInfo } from '../guards/auth-guard';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { USER_ROLE } from '../constant';
import TournamentView from '../sections/tournament/TournamentView';
import TournamentForm, { TOURNAMENT_FORM_KEY } from '../sections/tournament/TournamentForm';
import TournamentFormView from '../sections/tournament/TournamentFormView';

const Tournament = () => {

  const user = getUserInfo();

  const [searchParams] = useSearchParams();
  const action = searchParams.get("action") || "view";

  const [selectedTournament, setSelectedTournament] = useState(null);

  const navigate = useNavigate();

  const ActionButton = () => {

    if (user.role === USER_ROLE.ADMIN) {

      if (action !== "view" || selectedTournament) {

        return (
          <Button
            sx={{ mt: 3 }}
            variant="outlined"
            onClick={() => {setSelectedTournament(null); navigate("/tournament")}}
          >
            Back
          </Button>
        );
      }
      else {
        return (
          <>
            <Button
              component={Link}
              to={"/tournament?action=add&type=N"}
              sx={{ mt: 3 }}
              variant="outlined"
            >
              Create National
            </Button>
            <Button
              component={Link}
              to={"/tournament?action=add&type=I"}
              sx={{ mt: 3 }}
              variant="outlined"
            >
              Create International
            </Button>
          </>
        );
      }
    }
    else return <></>;
  }

  return (
    <Layout user={user} >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 1
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1} sx={{ width: "100%" }} >
                <Typography variant="h5">
                  Tournament
                </Typography>
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={1}
                  justifyContent={"right"}
                  sx={{ width: "100%" }}
                >
                  <ActionButton />
                </Stack>
              </Stack>
            </Stack>
            {
              (action !== "view" || (selectedTournament && selectedTournament[TOURNAMENT_FORM_KEY.CATEGORY] === "N"))?
              <TournamentForm selectedTournament = {selectedTournament} setSelectedTournament = {setSelectedTournament}/>
              :
              (selectedTournament && selectedTournament[TOURNAMENT_FORM_KEY.CATEGORY] === "I")?
              <TournamentFormView data={selectedTournament} />
              :
              <TournamentView setSelectedTournament = {setSelectedTournament} />
            }
          </Stack>
        </Container>
      </Box>
    </Layout>
  )
}

export default Tournament