import React, { useCallback, useEffect, useState } from 'react'
import { API_STFI } from '../../api/stfi';
import { ERROR_STR, HELPER_TEXT, infoProps } from '../../constant';
import { sortByKey } from '../../utils';
import { Box, Card, CardContent, CardHeader, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormLabel, Unstable_Grid2 as Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TOURNAMENT_FORM_KEY } from './TournamentForm';

const TournamentStatesTables = ({ tournament, states, selectState, selectAllStates, isChecked }) => {

    var arr = [];
    var events = tournament[TOURNAMENT_FORM_KEY.EVENTS];
    for (var i = 0; i < events.length; i++) {

        if (events[i].selected) {
            arr.push({ ...events[i], genderLabel: "M", genderKey: "qualifiedMaleStateCodes" });
            arr.push({ ...events[i], genderLabel: "F", genderKey: "qualifiedFemaleStateCodes" });
        }
    }

    return (
        <Card sx={{ height: "100%" }} >
            <CardHeader
                subheader=""
                title="Select States"
            />
            <CardContent sx={{ pt: 0 }}>
                <Grid
                    xs={12}
                    sx={{ p: 0, mt: 1 }}
                >
                    <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Box>State Name</Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box>State Code</Box>
                                    </TableCell>
                                    {
                                        arr.map((v, i) => {
                                            return (
                                                <TableCell key={i} >
                                                    <Box display={"flex"} alignItems={"center"}>
                                                        {
                                                            (v.event === "RE" || v.event === "DE" || v.event === "QE") ?
                                                                <FormControlLabel
                                                                    control={<Checkbox name={`${v.event}_${v.genderKey}`} onChange={selectAllStates} />}
                                                                />
                                                                :
                                                                <></>
                                                        }
                                                        <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                                                            <Box>{v.label} ({v.genderLabel})</Box>
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                            )
                                        })
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {states.map((data, i) => {

                                    return (
                                        <TableRow
                                            hover
                                            key={i}
                                        >
                                            <TableCell>
                                                {data.stateName}
                                            </TableCell>
                                            <TableCell>
                                                {data.stateCode}
                                            </TableCell>
                                            {
                                                arr.map((v, j) => {
                                                    return (
                                                        <TableCell key={j} >
                                                            <FormControlLabel
                                                                control={<Checkbox title={v.genderLabel} checked={isChecked(`${v.event}_${v.genderKey}_${data.stateCode}`)} name={`${v.event}_${v.genderKey}_${data.stateCode}`} onChange={selectState} />}
                                                            />
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </CardContent>
        </Card>
    );
};

const TournamentMemberCount = ({ data, header, name, eventOnhandleChange }) => {
    return (
        <Grid
            xs={12}
        >
            <Card sx={{ height: "100%" }} >
                <CardHeader
                    subheader=""
                    title={header}
                />
                <CardContent sx={{ pt: 0 }}>
                    <Grid
                        xs={12}
                        sx={{ p: 0, mt: 1 }}
                    >
                        <TextField
                            sx={{ mr: 2 }}
                            label={"Player Count"}
                            name={name + "_maximumNumberOfPlayers"}
                            value={data.maximumNumberOfPlayers}
                            required
                            helperText={HELPER_TEXT.ONLY_NUMBER_MAX_4}
                            inputProps={{ pattern: "^[2-9]|^[1][0-5]$" }}
                            onChange={eventOnhandleChange}
                        />
                        <TextField
                            sx={{ mr: 2 }}
                            label={"Coach Count"}
                            name={name + "_maximumNumberOfCoaches"}
                            required
                            value={data.maximumNumberOfCoaches}
                            helperText={HELPER_TEXT.ONLY_NUMBER_MAX_4}
                            inputProps={{ pattern: "^[1-3]$" }}
                            onChange={eventOnhandleChange}
                        />
                        <TextField
                            sx={{ mr: 2 }}
                            label={"Manager Count"}
                            name={name + "_maximumNumberOfManagers"}
                            required
                            value={data.maximumNumberOfManagers}
                            helperText={HELPER_TEXT.ONLY_NUMBER_MAX_4}
                            inputProps={{ pattern: "^[1-3]$" }}
                            onChange={eventOnhandleChange}
                        />
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

function TournamentEvents({ tournament, dispatch }) {

    const [states, setStates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState(infoProps.init());
    const navigate = useNavigate();

    const fetchData = useCallback(async () => {

        try {
            // Authenticate the google token
            const response = await API_STFI.STATES();
            setStates(sortByKey(response.data, "stateName"));

            // Hide loader
            setLoading(false);

            setInfo(infoProps.close());

        } catch (error) {

            navigate("/");
            // Hide loader
            setLoading(false);
            if (error.response) {
                console.error('Server responded with an error:', error.response.status, error.response);
                setInfo(infoProps.error(error.response.statusText));
            } else if (error.request) {
                console.error('No response received from the server');
                setInfo(infoProps.error(ERROR_STR.NO_RESPONSE));
            } else {
                console.error('Error setting up the request:', error.message);
                setInfo(infoProps.error(ERROR_STR.STD));
            }
        }
    }, [navigate]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const eventChange = useCallback(
        (event) => {

            const { name, checked } = event.target;

            dispatch({ type: "CHECK_EVENT", checked: checked, event: name })
        },
        [dispatch]
    );

    const eventOnhandleChange = useCallback(
        (event) => {

            const { name, value } = event.target;

            const [eventType, key] = name.split("_");

            dispatch({ type: "UPDATE_COUNT", event: eventType, value: value, name: key })
        },
        [dispatch]
    );

    const selectState = useCallback(
        (event) => {

            const { name, checked } = event.target;

            const [eventType, gender, stateCode] = name.split("_");

            dispatch({ type: "UPDATE_STATE", event: eventType, gender: gender, stateCode: stateCode, checked: checked })
        },
        [dispatch]
    );

    const selectAllStates = useCallback(
        (event) => {

            const { name, checked } = event.target;

            const [eventType, gender] = name.split("_");

            dispatch({ type: "SELECT_ALL", event: eventType, gender: gender, checked: checked, states: states });
        },
        [states, dispatch]
    );

    const isChecked = useCallback(
        (name) => {

            const [eventType, gender, stateCode] = name.split("_");

            var events = tournament[TOURNAMENT_FORM_KEY.EVENTS];
            for (let i = 0; i < events.length; i++) {
                if (events[i].event === eventType) {
                    return events[i][gender].includes(stateCode);
                }
            }
        },
        [tournament]
    );

    const hasEvents = () => {
        var flag = false;
        tournament[TOURNAMENT_FORM_KEY.EVENTS].map((e) => {
            if (e.selected) flag = true;
            return true;
        });
        return flag;
    };

    return (
        <>
            {
                (loading) ?
                    <Box sx={{ width: '100%', height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress />
                    </Box>
                    :
                    (info.open) ?
                        <Typography >{info.text}</Typography>
                        :
                        <Box mt={2}>
                            <Card sx={{ height: "100%" }}>
                                <CardHeader
                                    subheader={""}
                                    title={"Select Events"}
                                />
                                <CardContent sx={{ pt: 0 }}>
                                    <Box sx={{ m: -1.5 }}>
                                        <Grid
                                            container
                                            spacing={2}
                                        >
                                            <Grid
                                                xs={12}
                                            >
                                                <FormControl sx={{ m: 1 }}>
                                                    <FormLabel id="demo-radio-buttons-group-label">Events</FormLabel>
                                                    <FormGroup
                                                        row
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        name="radio-buttons-group"
                                                        onChange={eventChange}
                                                    >
                                                        {
                                                            (tournament[TOURNAMENT_FORM_KEY.EVENTS]).map((e, i) => {
                                                                return (
                                                                    <FormControlLabel key={i} name={e.event} control={<Checkbox checked={e.selected} />} label={e.label} />
                                                                )
                                                            })
                                                        }
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </CardContent>
                            </Card>
                            {
                                tournament[TOURNAMENT_FORM_KEY.EVENTS].map((e, i) => {
                                    return (
                                        (e.selected) ?
                                            <TournamentMemberCount key={i} data={e} header={e.label} name={e.event} eventOnhandleChange={eventOnhandleChange} />
                                            :
                                            <Box key={i} ></Box>
                                    )
                                })
                            }
                            {
                                (hasEvents()) ?
                                    <TournamentStatesTables tournament={tournament} states={states} selectState={selectState} selectAllStates={selectAllStates} isChecked={isChecked} />
                                    :
                                    <></>
                            }
                        </Box>
            }
        </>
    )
}

export default TournamentEvents