import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Layout } from '../layouts/dashboard/layout'
import { Box, Button, Container, Stack, Typography, Unstable_Grid2 as Grid} from '@mui/material';
import { getUserInfo } from '../guards/auth-guard';
import { Link, useSearchParams } from 'react-router-dom';
import { USER_ROLE } from '../constant';
import RegistrationsTable from '../sections/registrations/RegistrationsTable';
import { API_STFI } from '../api/stfi';
import RegistrationFilter from '../sections/registrations/RegistrationFilter';
import RegistrationPreview from '../sections/registrations/RegistrationPreview';
import { RegistrationForm2 } from '../sections/registrations/RegistrationForm2';
import { sortByKey } from '../utils';

const RegistrationsView = () => {

  const GetStateCodeList = () => {

    const [data, setData] = useState(null);

    const fetchData = useCallback(async () => {
        try {

            // Authenticate the google token
            const response = await API_STFI.STATES();

            setData(sortByKey(response.data, "stateName"));

        } catch (error) {
            console.log(error);
        }
    }, []);
  
    useEffect(() => {
        fetchData();
    }, [fetchData]);
  
    const cachedData = useMemo(() => data, [data]);
  
    return cachedData;
  };

  const user = getUserInfo();

  var stateCodeList = [];

  if(user.role === USER_ROLE.ADMIN) {
    stateCodeList = GetStateCodeList();
  }

  const [searchParams] = useSearchParams();
  const action = searchParams.get("action");

  const ActionButton = () => {

    // if(user.role === USER_ROLE.ADMIN) {

      if(action === "preview" || action === "resubmit") {
        return(
          <Grid
              md={12}
            >
            <Button
              component={Link}
              to={-2}
              sx={{ mt: 3 }}
              variant="outlined"
            >
              Back
            </Button>
          </Grid>
        );
      }
      else {
        return (
          <RegistrationFilter stateCodeList = {stateCodeList} user  = {user} />
        );
      }
    // }
    // else return <></>;
  }

  return (
    <Layout user = {user} >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 1
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1} sx={{width:"100%"}} >
                <Typography variant="h5">
                  Registrations
                </Typography>
                <Stack
                  alignItems="center"
                  spacing={1}
                  sx={{pt:3, flexDirection:{sm:"column", md:"row"}}}
                >
                  <ActionButton />
                </Stack>
              </Stack>
            </Stack>
            {
              (action === "preview")
              ?
              <RegistrationPreview />
              :
              (action === "resubmit")
              ?
              <RegistrationForm2 />
              :
              <RegistrationsTable user = {user} />
            } 
            
          </Stack>
        </Container>
      </Box>
    </Layout>
  )
}

export default RegistrationsView