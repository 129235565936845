import React, { useEffect, useState } from 'react'
import Dialog from '../../components/Dialog';
import Backdrop from '../../components/Backdrop';
import Snackbar from '../../components/Snackbar';
import Info from '../../components/Info';
import { Box, Button, CircularProgress, ImageList, ImageListItem, ImageListItemBar, Dialog as DialogM, DialogContent} from '@mui/material';
import { ERROR_STR, USER_ROLE, dialogButtonsProps, dialogProps, infoProps, snackbarProps } from '../../constant';
import { API_STFI } from '../../api/stfi';
import axios from 'axios';

const GalleryView = (props) => {

    const {user} = props;

    const [loading, setLoading] = useState(true);

    const [backdrop, setBackdrop] = useState(false);
  
    const [dialog, setDialog] = useState(dialogProps.init());
  
    const [snackbar, setSnackbar] = useState(snackbarProps.init());
    snackbar.hide = () => {
      setSnackbar(snackbarProps.hide());
    };

    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [loadinImg, setLoadingImg] = useState(false);

    const handleClickOpen = async (image) => {
      setLoadingImg(true);
      setOpen(true);
      try {
        const url = new URL(image);
        const path = url.pathname + url.search;
        const response = await axios.get(path, { responseType: 'blob' });
        const imageObjectURL = URL.createObjectURL(response.data);
        setSelectedImage(imageObjectURL);
      } catch (error) {
        console.error('Error fetching image:', error);
        handleClose();
      } finally {
        setLoadingImg(false);
      }
    };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
    setLoadingImg(false);
  };
  
    const [info, setInfo] = useState(infoProps.init());
  
    const [data, setData] = useState([]);

    const onDelete = async (id) => {
  
      var buttons = [];
      buttons.push(dialogButtonsProps.init("Delete", async () => {
  
        setDialog(dialogProps.close());
        setBackdrop(true);
  
          try {
  
            // Authenticate the google token
            const response = await API_STFI.GALLERY_DELETE(id.split("/").pop());
            console.log(response);
  
            // Hide loader
            setBackdrop(false);
  
            fetchData();
  
          } catch (error) {
  
            // Hide loader
            setBackdrop(false);
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error('Server responded with an error:', error.response.status, error.response);
              setSnackbar(snackbarProps.error(error.response.statusText));
            } else if (error.request) {
              // The request was made but no response was received
              console.error('No response received from the server');
              setSnackbar(snackbarProps.error(ERROR_STR.NO_RESPONSE));
            } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error setting up the request:', error.message);
              setSnackbar(snackbarProps.error(ERROR_STR.STD));
            }
          }
      }));
      buttons.push(dialogButtonsProps.init("Close", () => {
        setDialog(dialogProps.close());
      }));
  
      setDialog(dialogProps.info("Delete", "Do you want to delete?", buttons));
    };

    const fetchData = async () => {
      try {

        // Authenticate the google token
        const response = await API_STFI.GALLERY();
  
        setData(response.data);
  
        // Hide loader
        setLoading(false);
  
        setInfo(infoProps.close());
  
      } catch (error) {
  
        // Hide loader
        setLoading(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Server responded with an error:', error.response.status, error.response);
          setInfo(infoProps.error(error.response.statusText));
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received from the server');
          setInfo(infoProps.error(ERROR_STR.NO_RESPONSE));
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error setting up the request:', error.message);
          setInfo(infoProps.error(ERROR_STR.STD));
        }
      }
    }
  
    useEffect(() => {
  
      fetchData();
      
    }, []);

    const [loadedImages, setLoadedImages] = useState([]);

    const handleImageLoad = (item) => {
      console.log(loadedImages);
      setLoadedImages((prevLoadedImages) => [...prevLoadedImages, item]);
    };

    return (
      <>
        {
          (loading) ?
          <Box sx={{ width: '100%', height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
          </Box>
          :
          <>
          <Box >
          <ImageList sx={{ width: "100%", height: "100%"}} gap = {10} cols={2} >
            {data.slice().reverse().map((item) => (
              <ImageListItem sx = {{cursor:"pointer"}}key={item} onClick={() => handleClickOpen(item)} >
                <img
                  src={`${item}`}
                  alt={item}
                  loading="lazy"
                  onLoad={() => handleImageLoad(item)}
                />
                {
                (user.role === USER_ROLE.ADMIN)?
                <ImageListItemBar
                  actionIcon={
                    <Button onClick = {(e) => {e.stopPropagation(); onDelete(item)}} sx={{color:"white"}}>Delete</Button>
                  }
                />
                :
                <Box></Box>
                }
              </ImageListItem>
            ))}
          </ImageList>
          </Box>
          <DialogM open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogContent>
              {loadinImg ? (
                <Box sx={{ width: '100%', height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <img
                  src={selectedImage}
                  alt={selectedImage}
                  style={{
                    width: '100%',
                    height: 'auto',
                  }}
                />
              )}
            </DialogContent>
          </DialogM>
          <Info {...info} />
          <Snackbar {...snackbar} />
          <Backdrop open = {backdrop}/>
          <Dialog {...dialog} /> 
          </>
        }
      </>
    );
}

export default GalleryView